import axios from 'axios';

export const Request = (configs: any = {}) => {
  const _instance = axios.create({
    ...configs
  });

  return {
    get: _instance.get,
    post: _instance.post
  }
}

const promiseTogether = async (promises: Array<() => Promise<any>>) => {
  await Promise.all(promises.map((promise) => promise()));
};

export const promiseBatchWithTime = async (
  promises: Array<() => Promise<any>>,
  options = {
    perBatch: 5,
    time: 1000
  }
) => {
  while (promises.length) {
    await promiseTogether([
      ...promises.splice(0, options.perBatch),
      () => new Promise<void>((resolve) => setTimeout(resolve, options.time))
    ]);
  }
};

export default Request();