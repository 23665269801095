import React from "react";
import { Button, Checkbox } from "@fluentui/react-components";
import { Link } from "react-router-dom";
import { Typography } from "../../components";
import { useFormContext } from "react-hook-form";

export default function Tutorial() {
  const { register } = useFormContext();

  return (
    <>
      <Typography variant="h1" block className="text-blue-400">
        US & Canada Check and Verify
      </Typography>
      <Typography block className="mt-4 text-gray-500 italic">
        The US & Canada Check & Verify add-on allows you to parse, validate, correct and append your contacts' names,
        addresses, phone numbers, and email addresses, as well as GeoLocate mailing addresses.
      </Typography>
      <Typography block className="mt-2 text-gray-500 italic">
        If this is your first time using this add-on, you may want to click the button bellow for a quick tutorial that
        will get you up and running quickly.
      </Typography>
      <div className="my-8 flex justify-center">
        <Link
          to="https://wiki.melissadata.com/?title=Listware_for_Excel:US_Tutorial"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button appearance="primary">Go to tutorial</Button>
        </Link>
      </div>

      <Typography block className="mt-2">
        When you're ready to started, click the 'Next' button bellow
      </Typography>
      <Checkbox {...register("hideTutorial")} className="-ml-2" label="Don't show this screen" />
    </>
  );
}
