/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { Button, Field } from "@fluentui/react-components";
import { Dropdown, DropdownOption } from "../../components/Dropdown";
import { Dialog, Typography } from "../../components";
import { FormProvider, useForm } from "react-hook-form";
import { SERVICE_NAVIGATIONS } from "../../utilities/Constants";
import { RouterUtil } from "../../utilities/helpers";

export default function NavigationSelection() {
  const form = useForm<{ service: string }>({
    values: {
      service: "",
    },
    mode: "onChange",
  });

  const {
    formState: { errors },
    watch,
  } = form;
  const { service } = watch();

  const serviceDescription = React.useMemo(() => {
    return SERVICE_NAVIGATIONS.find((d) => d.path === service)?.description;
  }, [service]);

  const handleContinue = (values: { service: string }) => {
    if (!values.service) return;
    RouterUtil.instance.push(values.service);
  };

  return (
    <FormProvider {...form}>
      <form className="mt-5 flex justify-start items-start flex-col" onSubmit={form.handleSubmit(handleContinue)}>
        <Typography block italic className="text-gray-500">
          Select a service to get started:
        </Typography>
        <Field className="w-full" validationMessage={errors.service?.message?.toString()}>
          <Dropdown
            name="service"
            placeholder="Select Service"
            disablePlaceholder
            label=""
            options={SERVICE_NAVIGATIONS.map(
              (d) => ({ label: d.label, value: d.path, disabled: d.disabled } as DropdownOption)
            )}
          />
        </Field>
        {serviceDescription && (
          <Typography italic block className="mt-3 text-start text-gray-500">
            {serviceDescription}
          </Typography>
        )}

        <div className="mt-5">
          <Button type="submit" appearance="primary" disabled={!service}>
            Continue
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
