import EventEmitter from 'events'
import { ToastIntent } from "@fluentui/react-components";

export interface ToastOptions {
  message?: string | React.ReactNode;
  toastId: string;
  intent: ToastIntent;
  timeout?: number;
}

export class Toaster {
  private static _instance: EventEmitter;
  static get instance() {
    return this._instance || (this._instance = new EventEmitter())
  }

  private static get toastId() {
    return new Date().getTime().toString()
  }

  static warn(message: string | React.ReactNode): void {
    this.instance.emit('toast', {
      message,
      toastId: this.toastId,
      intent: 'warning',
      timeout: 2000
    })
  }

  static success(message: string | React.ReactNode): void {
    this.instance.emit('toast', {
      message,
      toastId: this.toastId,
      intent: 'success',
      timeout: 2000
    })
  }
}