import { eCustomerInfoPackages, eProcessingModes } from ".";

export class CreditReportingInfo {
    public infoPackage: eCustomerInfoPackages;
    public processingMode: eProcessingModes;
    public chargeResultCodes: string[] | null;
    public noChargeResultCodes: string[] | null;

    constructor(pkg: eCustomerInfoPackages, mode: eProcessingModes, chargeCodes: string[] | null, noChargeCodes: string[] | null) {
        this.infoPackage = pkg;
        this.processingMode = mode;
        this.chargeResultCodes = chargeCodes;
        this.noChargeResultCodes = noChargeCodes;
    }
}