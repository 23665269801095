import { PersonatorOutputField } from '../models'

export const OUTPUT_COLUMNS: PersonatorOutputField[] = [
    // NAME 1
    new PersonatorOutputField("NameFull", "Full Name", "NameFull", "name1", "cbOutFullName1", "FullName"),
    new PersonatorOutputField("NamePrefix", "Prefix", "NamePrefix", "name1", "cbOutPrefix1"),
    new PersonatorOutputField("NameFirst", "First Name", "NameFirst", "name1", "cbOutFirstName1", "FirstName"),
    new PersonatorOutputField("NameLast", "Last Name", "NameLast", "name1", "cbOutLastName1", "LastName"),
    new PersonatorOutputField("NameMiddle", "Middle Name", "NameMiddle", "name1", "cbOutMiddleName1"),
    new PersonatorOutputField("NameSuffix", "Suffix", "NameSuffix", "name1", "cbOutSuffix1"),
    new PersonatorOutputField("Gender", "Gender", "Gender", "name1", "cbOutGender1"),
    // SALUTATIONS
    new PersonatorOutputField("Salutation", "Salutation", "Salutation", "cbOutSalutation", "cbOutSalutation"),
    // ADDRESS
    new PersonatorOutputField("CompanyName", "Company", "CompanyName", "cbAddress", "cbOutCompany", "CompanyName"),
    new PersonatorOutputField("AddressLine1", "Address", "AddressLine1", "cbAddress", "cbOutAddress1", "AddressLine1"),
    new PersonatorOutputField("AddressLine2", "Address 2", "AddressLine2", "cbAddress", "cbOutAddress2", "AddressLine2"),
    new PersonatorOutputField("City", "City", "City", "cbAddress", "cbOutCity", "City"),
    new PersonatorOutputField("State", "State", "State", "cbAddress", "cbOutState", "State"),
    new PersonatorOutputField("PostalCode", "ZIP Code", "PostalCode", "cbAddress", "cbOutZipCode", "PostalCode"),
    new PersonatorOutputField("CountryCode", "Country", "CountryCode", "cbAddress", "cbOutCountry", "Country"),
    new PersonatorOutputField("AddressKey", "Address Key", "AddressKey", "cbAddress", "cbOutAddressKey"),
    new PersonatorOutputField("MAK", "MAK", "MelissaAddressKey", "cbAddress", "cbOutMAK"),
    new PersonatorOutputField("Base MAK", "Base MAK", "MelissaAddressKeyBase", "cbAddress", "cbOutBaseMAK"),
    // GEOLOCATION
    new PersonatorOutputField("Latitude", "Latitude & Longitude", "Latitude,Longitude", "cbOutGeoLocation", "cbOutLatitude"),
    // PHONE
    new PersonatorOutputField("PhoneNumber", "Phone", "PhoneNumber", "cbOutPhone", "cbOutPhone"),
    // EMAIL
    new PersonatorOutputField("EmailAddress", "Email", "EmailAddress", "cbOutEmail", "cbOutEmail"),
    // ADDRESS_INFORMATION
    new PersonatorOutputField("AddressTypeCode", "Address Type", "AddressTypeCode", "cbAddressInformation", "cbOutAddressType"),
    new PersonatorOutputField("DeliveryIndicator", "Delivery Indicator", "DeliveryIndicator", "cbAddressInformation", "cbOutDeliveryIndicator"),
    new PersonatorOutputField("AddressExtras", "Extra Address Information", "AddressExtras", "cbAddressInformation", "cbOutExtraAddress"),
    new PersonatorOutputField(
        "AddressSuiteNumber",
        "Suite",
        "AddressSuiteNumber",
        "cbAddressInformation",
        "cbOutSuite",
    ),
    new PersonatorOutputField(
        "PrivateMailBox",
        "Private Mailbox",
        "PrivateMailBox",
        "cbAddressInformation",
        "cbOutPrivateMailbox",
    ),
    new PersonatorOutputField(
        "UrbanizationName",
        "Urbanization (PR only)",
        "UrbanizationName",
        "cbAddressInformation",
        "cbOutUrbanization",
    ),
    new PersonatorOutputField(
        "CityAbbreviation",
        "City Abbreviation",
        "CityAbbreviation",
        "cbAddressInformation",
        "cbOutCityAbbreviation",
    ),
    new PersonatorOutputField("StateName", "State Name", "StateName", "cbAddressInformation", "cbOutStateName"),
    new PersonatorOutputField(
        "CountryCode",
        "ISO Country Code",
        "CountryCode",
        "cbAddressInformation",
        "cbOutCountryCode",
    ),
    new PersonatorOutputField(
        "CarrierRoute",
        "Carrier Route",
        "CarrierRoute",
        "cbAddressInformation",
        "cbOutCarrierRoute",
    ),
    new PersonatorOutputField(
        "DeliveryPointCode",
        "Delivery Point",
        "DeliveryPointCode",
        "cbAddressInformation",
        "cbOutDeliveryPoint",
    ),
    new PersonatorOutputField(
        "IPUTC",
        "Universal Time Code",
        "IPUTC",
        "cbAddressInformation",
        "cbOutUniversalTimeCode",
    ),
    // CENSUS_INFORMATION
    new PersonatorOutputField("CBSACode", "CBSA Code", "CBSACode", "cbCensusInformation", "cbOutCBSACode"),
    new PersonatorOutputField("CBSATitle", "CBSA Title", "CBSATitle", "cbCensusInformation", "cbOutCBSATitle"),
    new PersonatorOutputField("CBSALevel", "CBSA Level", "CBSALevel", "cbCensusInformation", "cbOutCBSALevel"),
    new PersonatorOutputField(
        "CBSADivisionCode",
        "Divisional CBSA Code",
        "CBSADivisionCode",
        "cbCensusInformation",
        "cbOutDivisionalCBSACode",
    ),
    new PersonatorOutputField(
        "CBSADivisionTitle",
        "Divisional CBSA Title",
        "CBSADivisionTitle",
        "cbCensusInformation",
        "cbOutDivisionalCBSATitle",
    ),
    new PersonatorOutputField(
        "CBSADivisionLevel",
        "Divisional CBSA Level",
        "CBSADivisionLevel",
        "cbCensusInformation",
        "cbOutDivisionalCBSALevel",
    ),
    new PersonatorOutputField("County", "County", "CountyName", "cbCensusInformation", "cbOutCountyName"),
    new PersonatorOutputField(
        "CountyFIPS",
        "FIPS/Tract/Block",
        "CountyFIPS",
        "cbCensusInformation",
        "cbOutFIPS",
    ),
    new PersonatorOutputField("PlaceName", "Place Name", "PlaceName", "cbCensusInformation", "cbOutPlaceName"),
    new PersonatorOutputField("PlaceCode", "Place Code", "PlaceCode", "cbCensusInformation", "cbOutPlaceCode"),
    new PersonatorOutputField(
        "CongressionalDistrict",
        "Congressional District",
        "CongressionalDistrict",
        "cbCensusInformation",
        "cbOutCongressionalDistrict",
    ),
    new PersonatorOutputField("CensusKey", "Census Key", "CensusKey", "cbCensusInformation", "cbOutCensusKey"),
    new PersonatorOutputField(
        "CountySubdivisionCode",
        "County Subdivision Code",
        "CountySubdivisionCode",
        "cbCensusInformation",
        "cbOutCountySubdivisionCode",
    ),
    new PersonatorOutputField(
        "CountySubdivisionName",
        "County Subdivision Name",
        "CountySubdivisionName",
        "cbCensusInformation",
        "cbOutCountySubdivisionName",

    ),
    new PersonatorOutputField(
        "StateDistrictUpper",
        "Upper State District Code",
        "StateDistrictUpper",
        "cbCensusInformation",
        "cbOutStateDistrictUpper",

    ),
    new PersonatorOutputField(
        "StateDistrictLower",
        "Lower State District Code",
        "StateDistrictLower",
        "cbCensusInformation",
        "cbOutStateDistrictLower",

    ),
    new PersonatorOutputField(
        "ElementarySchoolDistrictCode",
        "Elem. School Dist. Code",
        "ElementarySchoolDistrictCode",
        "cbCensusInformation",
        "cbOutElementarySchoolDistrictCode",

    ),
    new PersonatorOutputField(
        "ElementarySchoolDistrictName",
        "Elem. School Dist. Name",
        "ElementarySchoolDistrictName",
        "cbCensusInformation",
        "cbOutElementarySchoolDistrictName",

    ),
    new PersonatorOutputField(
        "SecondarySchoolDistrictCode",
        "Second. School Dist. Code",
        "SecondarySchoolDistrictCode",
        "cbCensusInformation",
        "cbOutSecondarySchoolDistrictCode",

    ),
    new PersonatorOutputField(
        "SecondarySchoolDistrictName",
        "Second. School Dist. Name",
        "SecondarySchoolDistrictName",
        "cbCensusInformation",
        "cbOutSecondarySchoolDistrictName",

    ),
    new PersonatorOutputField(
        "UnifiedSchoolDistrictCode",
        "Unified School Dist. Code",
        "UnifiedSchoolDistrictCode",
        "cbCensusInformation",
        "cbOutUnifiedSchoolDistrictCode",

    ),
    new PersonatorOutputField(
        "UnifiedSchoolDistrictName",
        "Unified School Dist. Name",
        "UnifiedSchoolDistrictName",
        "cbCensusInformation",
        "cbOutUnifiedSchoolDistrictName",

    ),
    // DEMOGRAPHICS
    new PersonatorOutputField(
        "DateOfBirth",
        "Date of Birth",
        "DateOfBirth",
        "cbDemographics",
        "cbOutDateOfBirth",

    ),
    new PersonatorOutputField(
        "DateOfDeath",
        "Date of Death",
        "DateOfDeath",
        "cbDemographics",
        "cbOutDateOfDeath",

    ),
    new PersonatorOutputField(
        "HouseholdIncome",
        "Household Income",
        "HouseholdIncome",
        "cbDemographics",
        "cbOutHouseholdIncome",

    ),
    new PersonatorOutputField("OwnRent", "Residence Type", "OwnRent", "cbDemographics", "cbOutResidenceType"),
    new PersonatorOutputField(
        "LengthOfResidence",
        "Length of Residence",
        "LengthOfResidence",
        "cbDemographics",
        "cbOutLengthOfResidence",

    ),
    new PersonatorOutputField("Occupation", "Occupation", "Occupation", "cbDemographics", "cbOutOccupation"),
    new PersonatorOutputField(
        "MaritalStatus",
        "Marital Status",
        "MaritalStatus",
        "cbDemographics",
        "cbOutMaritalStatus",

    ),
    new PersonatorOutputField(
        "PresenceOfChildren",
        "Presence of Children",
        "PresenceOfChildren",
        "cbDemographics",
        "cbOutChildren",

    ),
    new PersonatorOutputField("Education", "Education", "Education", "cbDemographics", "cbOutEducation"),
    new PersonatorOutputField(
        "HouseholdSize",
        "Household Size",
        "HouseholdSize",
        "cbDemographics",
        "cbOutHouseholdSize",

    ),
    new PersonatorOutputField(
        "PoliticalParty",
        "Political Party",
        "PoliticalParty",
        "cbDemographics",
        "cbOutPoliticalParty",

    ),
    new PersonatorOutputField(
        "PresenceOfSenior",
        "Presence of Senior",
        "PresenceOfSenior",
        "cbDemographics",
        "cbOutPresenceOfSenior",

    ),
    new PersonatorOutputField(
        "CreditCardUser",
        "Credit Card User",
        "CreditCardUser",
        "cbDemographics",
        "cbOutCreditCardUser",

    ),
    new PersonatorOutputField("EthnicCode", "Ethnic Code", "EthnicCode", "cbDemographics", "cbOutEthnicCode"),
    new PersonatorOutputField(
        "EthnicGroup",
        "Ethnic Group",
        "EthnicGroup",
        "cbDemographics",
        "cbOutEthnicGroup",

    ),
    new PersonatorOutputField(
        "ChildrenAgeRange",
        "Children Age Range",
        "ChildrenAgeRange",
        "cbDemographics",
        "cbOutChildrenAgeRange",
    ),
    // PARSED_ADDRESS
    new PersonatorOutputField(
        "AddressHouseNumber",
        "House Number",
        "AddressHouseNumber",
        "cbParsedAddress",
        "cbOutHouseNumber",
    ),
    new PersonatorOutputField(
        "AddressPreDirection",
        "Street Pre-Directional",
        "AddressPreDirection",
        "cbParsedAddress",
        "cbOutStreetPreDirectional",
    ),
    new PersonatorOutputField(
        "AddressStreetName",
        "Street Name",
        "AddressStreetName",
        "cbParsedAddress",
        "cbOutStreetName",
    ),
    new PersonatorOutputField(
        "AddressStreetSuffix",
        "Street Suffix/Type",
        "AddressStreetSuffix",
        "cbParsedAddress",
        "cbOutStreetSuffix",
    ),
    new PersonatorOutputField(
        "AddressPostDirection",
        "Street Post-Directional",
        "AddressPostDirection",
        "cbParsedAddress",
        "cbOutStreetPostDirectional",
    ),
    new PersonatorOutputField(
        "AddressSuiteName",
        "Suite Name",
        "AddressSuiteName",
        "cbParsedAddress",
        "cbOutSuiteName",
    ),
    new PersonatorOutputField(
        "AddressSuiteNumber",
        "Suite Number",
        "AddressSuiteNumber",
        "cbParsedAddress",
        "cbOutSuiteNumber",
    ),
    new PersonatorOutputField(
        "AddressPrivateMailboxName",
        "Private Mailbox Name",
        "AddressPrivateMailboxName",
        "cbParsedAddress",
        "cbOutPrivateMailboxName",
    ),
    new PersonatorOutputField(
        "AddressPrivateMailboxRange",
        "Private Mailbox Number",
        "AddressPrivateMailboxRange",
        "cbParsedAddress",
        "cbOutPrivateMailboxNumber",
    ),
    new PersonatorOutputField(
        "AddressRouteService",
        "Route Service Number (Can only)",
        "AddressRouteService",
        "cbParsedAddress",
        "cbOutRouteService",
    ),
    new PersonatorOutputField(
        "AddressDeliveryInstallation",
        "Delivery Installation (Can only)",
        "AddressDeliveryInstallation",
        "cbParsedAddress",
        "cbOutDeliveryInstallation",
    ),
    new PersonatorOutputField(
        "AddressLockBox",
        "Lock Box Number (Can only)",
        "AddressLockBox",
        "cbParsedAddress",
        "cbOutLockBox",
    ),
];
