import React from "react";
import { Radio, RadioGroup } from "@fluentui/react-components";
import { Typography } from "../../components";
import { useFormContext, useWatch } from "react-hook-form";
import { usePersonatorContext } from "../../state/Personator";
import { PersonatorFormValues } from "../../utilities/models";
import { OPERATIONS } from "../../utilities/Constants";
import EstimateCreditUsage from "./EstimateCreditUsage";
import useCustomerLicenseType from "../../hooks/useCustomerLicenseType";

export default function ReviewSetting() {
  const {
    state: { sheet },
  } = usePersonatorContext();
  const { usingCredits } = useCustomerLicenseType();
  const { getValues, setValue, control } = useFormContext<PersonatorFormValues>();
  const method = useWatch({
    control,
    name: "method",
  });
  const values = React.useMemo(() => getValues(), []);

  return (
    <>
      <Typography variant="h1" block className="text-blue-400">
        Review Settings
      </Typography>
      <Typography block className="mt-2 text-gray-500 italic">
        Please review the settings you have selected and change any that aren't correct. When complete, click 'Process'
        below to start processing.
      </Typography>
      <Typography variant="h2" block className="text-red-800 mt-3">
        Warning
      </Typography>
      <Typography block className="mt-2 text-gray-500 italic">
        It's strongly recommended that you refrain from editing your spreadsheet while Listware is processing.
      </Typography>
      <Typography variant="h2" block className="text-blue-400 mt-3">
        Range
      </Typography>
      <Typography block className="mt-2">
        Sheet: {sheet?.name}
      </Typography>
      <Typography block className="mt-2">
        Range: {sheet?.totalRows || 0} Rows
      </Typography>
      <Typography variant="h2" block className="text-blue-400 mt-3">
        Options
      </Typography>
      <Typography block className="mt-2">
        {values.operations.map(opt => OPERATIONS.find(op => op.value === opt)?.label).join(", ")}
      </Typography>
      <Typography variant="h2" block className="text-blue-400 mt-3">
        Reporting
      </Typography>
      <Typography block className="mt-2">
        A report will be created and inserted to the Spreedsheet.
      </Typography>
      {usingCredits && (
        <>
          <Typography variant="h2" block className="text-blue-400 mt-3">
            Account Information
          </Typography>
          <EstimateCreditUsage isReview showCreditUsageLink />
        </>
      )}
      <Typography variant="h2" block className="text-blue-400 mt-3">
        Append's Method
      </Typography>
      <RadioGroup value={method} name="method" onChange={(_, d) => setValue("method", d?.value as "export" | "append")}>
        <Radio value="append" label="Append to the next columns" />
        <Radio value="export" label="Export to new sheet" />
      </RadioGroup>
    </>
  );
}
