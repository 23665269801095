import { Link } from "@fluentui/react-components";
import React from "react";
import { APP_CONFIG } from "../utilities/Constants";

export default function AppVersion() {
  return (
    <div className="fixed bottom-0 h-7 w-full text-center bg-white">
      <span>Version: {APP_CONFIG.APP_VERSION}</span> | <Link target="_blank" href={APP_CONFIG.URL.aboutMelissaURL}>About Melissa</Link>
    </div>
  );
}
