export * from './PackageInfo'
export * from './PersonatorOutputField'

export enum eTokenServerResult {
    NoError,
    Connectivity,
    InternalError,         // SE01
    MissingCustomerId,     // TE01
    InvalidCustomerId,     // TE02
    CustomerNotFound,      // TE03
    UnauthorizedPackage,   // TE04
    NoRemainingRecords,    // TE05
    InvalidProductId,      // TE06
    NoPAF,                 // TE07
    ExpiredPAF,            // TE08
    UnconfirmedPAF,        // TE09
    MultiplePAFs,          // TE10
    UsingFullService,      // TS01
    UsingCredits,          // TS02
    Unknown
}

export enum eProcessingModes {
    None = 0x0000000000000000,
    USCheck = 0x0000000000000001,
    USVerify = 0x0000000000000002,
    USMove = 0x0000000000000004,
    USGeoCode = 0x0000000000000008,
    USGeoPoint = 0x0000000000000010,
    USAppendName = 0x0000000000000020,
    USAppendAddress = 0x0000000000000040,
    USAppendPhone = 0x0000000000000080,
    USAppendEmail = 0x0000000000000100,
    USDemographicDateOfBirth = 0x0000000000000200,
    USDemographicDateOfDeath = 0x0000000000000400,
    USDemographicGender = 0x0000000000000800,
    USDemographicHouseholdIncome = 0x0000000000001000,
    USDemographicResidenceType = 0x0000000000002000,
    USDemographicLengthOfResidence = 0x0000000000004000,
    USDemographicOccupation = 0x0000000000008000,
    USDemographicMaritalStatus = 0x0000000000010000,
    USDemographicChildren = 0x0000000000020000,
    USDemographicEducation = 0x0000000000040000,
    USDemographicHouseholdSize = 0x0000000000080000,
    USDemographicPoliticalParty = 0x0000000000100000,
    USDemographicPresenceOfSenior = 0x0000000000200000,
    USDemographicCreditCardUser = 0x0000000000400000,
    USDemographicEthnicCode = 0x0000000000800000,
    USDemographicEthnicGroup = 0x0000000001000000,
    USDemographicChildrenAgeRange = 0x0000000002000000,

    USProperty = 0x0000000004000000,

    IntCheck = 0x0000000008000000,
    IntGeoCode = 0x0000000010000000,
    IntGeoPoint = 0x0000000020000000,

    SmartMoverCheck = 0x0000000040000000,
    SmartMoverNCOA = 0x0000000080000000,
    SmartMoverCCOA = 0x0000000100000000,

    BusinessCoder_Listware = 0x0000000200000000,
    BusinessCoder_AddressValidate = 0x0000000400000000,
    BusinessCoder_GeoPoint = 0x0000000800000000,
    BusinessCoder_FirmgraphicsAppend = 0x0000001000000000,

    MatchUpCheck = 0x0000002000000000,
    MatchUpDedupe = 0x0000004000000000,

    ExpressEntry = 0x0000008000000000,

    ReverseGeo = 0x0000010000000000,

    USAPPEND = USAppendName | USAppendAddress | USAppendPhone | USAppendEmail,
    USDEMOGRAPHICS = USDemographicDateOfBirth | USDemographicDateOfDeath | USDemographicGender | USDemographicHouseholdIncome | USDemographicResidenceType | USDemographicLengthOfResidence | USDemographicOccupation | USDemographicMaritalStatus | USDemographicChildren | USDemographicEducation | USDemographicHouseholdSize | USDemographicPoliticalParty | USDemographicPresenceOfSenior | USDemographicCreditCardUser | USDemographicEthnicCode | USDemographicEthnicGroup | USDemographicChildrenAgeRange,
    USALL = USCheck | USVerify | USMove | USGeoCode | USGeoPoint | USProperty | USAPPEND | USDEMOGRAPHICS,
    INTALL = IntCheck | IntGeoCode | IntGeoPoint,
    SMARTMOVERALL = SmartMoverCheck | SmartMoverNCOA | SmartMoverCCOA,
    BUSINESSCODERALL = BusinessCoder_Listware | BusinessCoder_AddressValidate | BusinessCoder_GeoPoint | BusinessCoder_FirmgraphicsAppend,
    MATCHUPALL = MatchUpCheck | MatchUpDedupe,

    ALL = ExpressEntry | USALL | INTALL | SMARTMOVERALL | BUSINESSCODERALL | MATCHUPALL | ReverseGeo
}

export enum eCustomerInfoPackages {
    USCheck = 0,                         // 2700  pkgBorgCheck
    USVerify = 1,                        // 2701  pkgBorgVerify
    USMove = 2,                          // 2702  pkgBorgMoveUpdate
    USGeoCode = 3,                       // 2734  pkgBorgGeoCode
    USGeoPoint = 4,                      // 2703  pkgBorgGeoPoint
    USAppendName = 5,                    // 2704  pkgBorgAppend
    USAppendAddress = 6,                 // 2705  pkgBorgAppend
    USAppendPhone = 7,                   // 2706  pkgBorgAppend
    USAppendEmail = 8,                   // 2707  pkgBorgAppend
    USDemographicDateOfBirth = 9,        // 2710 pkgBorgDemographics
    USDemographicDateOfDeath = 10,       // 2711 pkgBorgDemographics
    USDemographicGender = 11,            // 2712 pkgBorgDemographics
    USDemographicHouseholdIncome = 12,   // 2713 pkgBorgDemographics
    USDemographicResidenceType = 13,     // 2714 pkgBorgDemographics
    USDemographicLengthOfResidence = 14, // 2715 pkgBorgDemographics
    USDemographicOccupation = 15,        // 2716 pkgBorgDemographics
    USDemographicMaritalStatus = 16,     // 2717 pkgBorgDemographics
    USDemographicChildren = 17,          // 2718 pkgBorgDemographics
    USDemographicEducation = 18,         // 2740 pkgBorgDemographics
    USDemographicHouseholdSize = 19,	 // 2741 pkgBorgDemographics
    USDemographicPoliticalParty = 20,    // 2742 pkgBorgDemographics
    USDemographicPresenceOfSenior = 21,	 // 2743 pkgBorgDemographics
    USDemographicCreditCardUser = 22,	 // 2744 pkgBorgDemographics
    USDemographicEthnicCode = 23,		 // 2728 pkgBorgDemographics
    USDemographicEthnicGroup = 24,		 // 2729 pkgBorgDemographics
    USDemographicChildrenAgeRange = 25,  // 2719 pkgBorgDemographics

    USProperty = 26,                     // 7744 pkgPropertySimple
    IntCheck = 27,                       // 2708 pkgIntAddressCheck
    IntGeoCode = 28,                     // 2734 pkgIntGeoCode
    IntGeoPoint = 29,                    // 2709 pkgIntGeoPoint
    SmartMoverCheck = 30,                // 2730 pkgSmartMoverCheck
    SmartMoverNCOA = 31,                 // 2731 pkgSmartMover
    SmartMoverCCOA = 32,                 // 2732 pkgCCOASmartMover
    BusinessCoder_Listware = 33,         // 2773 pkgBusinessCoder
    BusinessCoder_AddressValidate = 34,  // 2774 pkgBusinessCoder
    BusinessCoder_Geopoint = 35,         // 2775 pkgBusinessCoder
    BusinessCoder_FirmgraphicsAppend = 36, // 2776 pkgBusinessCoder
    MatchUpCheck = 37,                   // 2737 pkgMatchUp
    MatchUpDedupe = 38,                  // 2738 pkgMatchUp
    ExpressEntry = 39,                   // 2699 pkgExpressEntry
    ReverseGeo = 40                      // 2753 pkgReverseGeo
};

export enum OfficePersitProperty {
    HideTutorial,
    License,
    Customer
}

export enum PersonatorFormStep {
    Tutorial,
    Operation,
    InputColumn,
    OutputColumn,
    ReviewSetting,
}

export interface PersonatorFormValues {
    hideTutorial?: boolean;
    operations: Operation[];
    FullName?: string;
    FirstName?: string;
    LastName?: string;
    AddressLine1?: string;
    CompanyName?: string;
    AddressLine2?: string;
    City?: string;
    State?: string;
    PostalCode?: string;
    PhoneNumber?: string;
    EmailAddress?: string;
    name1?: string[];
    cbOutSalutation?: string[];
    cbAddress?: string[];
    cbOutGeoLocation?: string[];
    cbOutPhone?: string[];
    cbOutEmail?: string[];
    cbAddressInformation?: string[];
    cbCensusInformation?: string[];
    cbDemographics?: string[];
    cbParsedAddress?: string[];
    method: "export" | "append";
    processingMode: eProcessingModes
}

export interface CustomerInfoResp {
    Result: string;
    CustomerId: string;
    TotalCredits: number;
    TotalProductRecords: number;
    TotalPackageRecords: number;
    ProductRecord: any[];
    PackageRecord: any[];
    SubcriptionInfo?: Record<string, string>;
    LicenseType?: eTokenServerResult;
}

export class ProcessingCount {
    processingMode: eProcessingModes;
    cost: number;
    count: number;
    constructor(mode: eProcessingModes) { this.processingMode = mode; this.cost = 0; this.count = 0; }
}