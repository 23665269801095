export const APP_CONFIG: AppConfig = {
  URL: {
    creditURL: "https://creditservice.melissadata.net/v3/json/service.svc",
    personatorURL: "https://personator.melissadata.net/v3/WEB/ContactVerify/doContactVerify",
    purchaseCreditURL: "https://www.melissa.com/pricing/purchase",
    registerURL: "https://apps.melissa.com/user/new_user.aspx",
    aboutMelissaURL: "https://www.melissa.com/data-quality-tools",
    creditUsageURL: "https://www.melissa.com/pricing/listware",
  },
  PRODUCT: "LW4O",
  APP_VERSION: "1.0.8",
  SOURCE_ID: "2600",
};
