export class RouterUtil {
  private static _instance: RouterUtil;

  static get instance() {
    return this._instance || (this._instance = new this())
  }

  push(hashPath: string) {
    window.location.hash = hashPath;
  }
}