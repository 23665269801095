import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { OfficeExcelConfig } from "../utilities/helpers";
import { OfficePersitProperty } from "../utilities/models";

const ProtectedRoute = ({ redirectPath = "/setting", children }) => {
  const redirectTo = useLocation();
  if (!OfficeExcelConfig.instance.getConfig(OfficePersitProperty.License)) {
    return <Navigate to={redirectPath} replace state={{ redirectTo }} />;
  }
  return children;
};

export default ProtectedRoute;
