export class PersonatorOutputField implements OutputColumn {
    prefix?: string;
    header: string;
    value?: string;
    label: string;
    field: string;
    mapFrom?: string;

    constructor(header: string, label: string, field: string, prefix?: string, value?: string, mapFrom?: string) {
        this.prefix = prefix;
        this.header = header;
        this.value = value;
        this.label = label;
        this.field = field;
        this.mapFrom = mapFrom;
    }
}