import React from "react";
import { Field } from "@fluentui/react-components";
import { Typography } from "../../components";
import { Dropdown, DropdownOption } from "../../components/Dropdown";
import { useFormContext } from "react-hook-form";
import { INPUT_COLS } from "../../utilities/Constants";
import { usePersonatorContext } from "../../state/Personator";

const InputDropdown = ({ label, name }: { label: string; name: string }) => {
  const {
    formState: { errors },
  } = useFormContext();
  const {
    state: { sheet },
  } = usePersonatorContext();

  const options = sheet.headers.map(
    (d) =>
      ({
        key: d.address.toString(),
        value: d.colIndex.toString(),
        label: d.label,
      } as DropdownOption)
  );

  return (
    <Field validationMessage={errors[name]?.message?.toString()}>
      <Dropdown {...{ label, name, options }} />
    </Field>
  );
};

export default function InputColumns() {
  const nameFields = React.useMemo(() => INPUT_COLS.filter((d) => d.prefix === "name"), []);
  const addressFields = React.useMemo(() => INPUT_COLS.filter((d) => d.prefix === "address"), []);

  return (
    <>
      <Typography variant="h1" block className="text-blue-400">
        Input Columns
      </Typography>
      <Typography block className="mt-2 text-gray-500 italic">
        Specify the columns which contains the data you want to process
      </Typography>
      <Typography variant="h2" block className="text-blue-400 mt-4">
        Name
      </Typography>
      <Typography block className="mt-2 text-gray-500 italic">
        Specify either a Full Name or First and Last Name
      </Typography>
      {nameFields.map((f) => (
        <InputDropdown key={f.id} label={f.label} name={f.id} />
      ))}
      <Typography variant="h2" block className="text-blue-400 mt-4">
        Address
      </Typography>
      {addressFields.map((f) => (
        <InputDropdown key={f.id} label={f.label} name={f.id} />
      ))}
      <Typography variant="h2" block className="text-blue-400 mt-4">
        Phone
      </Typography>
      <InputDropdown label="Phone:" name="PhoneNumber" />
      <Typography variant="h2" block className="text-blue-400 mt-4">
        Email
      </Typography>
      <InputDropdown label="Email:" name="EmailAddress" />
    </>
  );
}
