import {
  APP_CONFIG,
  BATCH_SIZE,
  CreditReportingInfos,
  OUTPUT_COLUMNS,
  OUTPUT_KEYS_ACCORDIONS,
  RECORDS_PER_BATCH,
} from "../utilities/Constants";
import { ReportService } from "./Report";
import Request from "../utilities/helpers/Request";
import {
  OfficeExcelConfig,
  formatCredit,
  getDefaultFontFormat,
  getProcessingModeProdsAndPkgs,
  getStatisticalResults,
  processingModeToSingleQueryPackageIds,
  productIdToProcessingMode,
  promiseBatchWithTime,
} from "../utilities/helpers";
import {
  CustomerInfoResp,
  OfficePersitProperty,
  PersonatorFormValues,
  ProcessingCount,
  eProcessingModes,
  eTokenServerResult,
} from "../utilities/models";
import { AuthService } from "./Auth";

const { URL, SOURCE_ID, APP_VERSION, PRODUCT } = APP_CONFIG;
const InputFields = [
  "FullName",
  "FirstName",
  "LastName",
  "AddressLine1",
  "CompanyName",
  "AddressLine2",
  "City",
  "State",
  "PostalCode",
  "PhoneNumber",
  "EmailAddress",
];

const OutputKeys = Object.keys(OUTPUT_KEYS_ACCORDIONS);

const Options: string[] = ["NameHint:DefinitelyFull", "AdvancedAddressCorrection:On"];

export class PersonatorService {
  private static _instance: PersonatorService;
  private token: string;
  private columns: OutputColumn[];
  private sheet: ActiveSheet;
  private formValues: PersonatorFormValues;
  private creditsConsumed: number = 0;
  private creditsRemaining: number = 0;
  private creditStatistics: ProcessingCount[] = null;

  static get instance() {
    return this._instance || (this._instance = new this());
  }

  async checkCredits(): Promise<Partial<CheckCreditResponse & { Success: boolean }>> {
    try {
      const { P, K } = getProcessingModeProdsAndPkgs(this.formValues?.processingMode);
      const res = await Request.get<CheckCreditResponse>(`${URL.creditURL}/CheckCredits`, {
        params: {
          L: OfficeExcelConfig.instance.getConfig(OfficePersitProperty.License),
          Q: this.sheet.totalRows.toString(),
          P,
          K,
        },
      });
      this.token = res?.data.Token;
      return { ...res?.data, Success: !!res?.data.Token };
    } catch (e) {
      return { Success: false };
    }
  }

  async getConsumeCredits() {
    const RETRIES = 3;
    let lastErrorMessage = "";
    const creditStatistics = this.creditStatistics.filter((f) => f.count > 0);

    //With the REST request version of this service, we have to make a call for each record instead of packaging up all the records into one call. Making individual calls adds about 6 seconds
    for (let i = 0; i < creditStatistics.length; i++) {
      const { P } = getProcessingModeProdsAndPkgs(creditStatistics[i].processingMode);
      const K = processingModeToSingleQueryPackageIds(creditStatistics[i].processingMode);
      const quantity = creditStatistics[i].count;

      for (let j = 0; j < RETRIES; j++) {
        try {
          const res = await Request.get<ConsumeCreditsResponse>(`${URL.creditURL}/ConsumeCreditsEx`, {
            params: {
              L: OfficeExcelConfig.instance.getConfig(OfficePersitProperty.License),
              Q: quantity.toString(),
              S: SOURCE_ID,
              P,
              K,
            },
          });
          lastErrorMessage = "";
          const response = res.data;
          this.creditsConsumed += response?.ConsumedCredits;
          this.creditsRemaining = response?.CreditBalance;
          break;
        } catch (ex) {
          lastErrorMessage = ex?.message;
        }
      }
    }

    if (lastErrorMessage) this.creditsRemaining = this.creditsRemaining - 1;
  }

  estimateCreditUsage = (
    prodMode: eProcessingModes,
    records: number
  ): { creditBalance: number; lowEstimate: string; highEstimate: string; estimated: boolean } => {
    const customerInfoResp: CustomerInfoResp = OfficeExcelConfig.instance.getConfig(
      OfficePersitProperty.Customer,
      true
    );
    let retVal: any;
    let lowCreditsPerRecord = 0;
    let highCreditsPerRecord = 0;
    for (
      let i = 0;
      customerInfoResp != null &&
      customerInfoResp.PackageRecord != null &&
      i < customerInfoResp.PackageRecord.length &&
      i < customerInfoResp.ProductRecord.length;
      i++
    ) {
      if ((prodMode & productIdToProcessingMode(customerInfoResp.ProductRecord[i].Product)) != eProcessingModes.None) {
        lowCreditsPerRecord +=
          customerInfoResp.ProductRecord[i].Cost * customerInfoResp.ProductRecord[i].ProbabilityOfSuccess;
        highCreditsPerRecord += customerInfoResp.ProductRecord[i].Cost;
        retVal =
          retVal ||
          (customerInfoResp.ProductRecord[i].ProbabilityOfSuccess > 0 &&
            customerInfoResp.ProductRecord[i].ProbabilityOfSuccess < 1 &&
            customerInfoResp.ProductRecord[i].Cost > 0);
      }
    }
    let lowEstimate = records * lowCreditsPerRecord;
    let highEstimate = records * highCreditsPerRecord;

    return {
      creditBalance: customerInfoResp.TotalCredits,
      lowEstimate: formatCredit(lowEstimate, 1),
      highEstimate: formatCredit(highEstimate, 1),
      estimated: retVal,
    };
  };

  async doVerify({
    formValues,
    activeSheet,
  }: {
    formValues: PersonatorFormValues;
    activeSheet: ActiveSheet;
  }): Promise<ReportResult> {
    this.sheet = activeSheet;
    this.formValues = formValues;
    this.creditStatistics = AuthService.instance.createProcessingCountArray();
    if (!(await this.checkCredits())?.Success)
      throw new Error("Oops! Error while checking the credits, please check again.");

    this.getRequestColumns(formValues);
    if (formValues.method == "export") await this.cloneSheet();

    await this.processAppendHeaders();

    const requests = await this.accoumulateRequests();
    await promiseBatchWithTime(requests, { perBatch: RECORDS_PER_BATCH, time: 200 }).then(async () => {
      const license: string = OfficeExcelConfig.instance.getConfig(OfficePersitProperty.License);
      const customer: CustomerInfoResp = OfficeExcelConfig.instance.getConfig(OfficePersitProperty.Customer, true);
      await Promise.all([
        customer.LicenseType === eTokenServerResult.NoError || customer.LicenseType === eTokenServerResult.UsingCredits
          ? this.getConsumeCredits()
          : Promise.resolve(),
        AuthService.instance.getCustomerInfo(license),
      ]);
      console.log("Yay! done.");
    });

    return ReportService.instance.generateReport(activeSheet, {
      creditsRemaining: this.creditsRemaining,
      creditsConsumed: this.creditsConsumed,
    });
  }

  private getRequestColumns({ operations, ...values }: PersonatorFormValues) {
    let columns: OutputColumn[] = OutputKeys.reduce((cols: OutputColumn[], key: string) => {
      const source = OUTPUT_COLUMNS.filter((f) => f.prefix === key) || [];
      const fieldValues: OutputColumn[] = (values[key] || []).map((field) => source.find((f) => f.field === field));
      return [...cols, ...fieldValues];
    }, []).filter((f) => f.field);

    // Handle for Latitude & Longitude
    if (values?.cbOutGeoLocation?.length) {
      columns = [
        ...columns,
        ...values.cbOutGeoLocation[0].split(",").map((st) => ({
          field: st,
          label: st,
          header: st,
        })),
      ];
      columns = columns.filter((col) => col.field !== values.cbOutGeoLocation[0]);
    }

    if (operations.includes("Move"))
      columns = [...columns, { field: "MoveDate", label: "MoveDate", header: "MoveDate" }];

    columns = [...columns, { field: "Results", label: "Results", header: "Results" }];
    this.columns = columns;
    return columns;
  }

  private async processAppendHeaders() {
    const { name, lastCol } = this.sheet;
    const { name: fontName, size } = await getDefaultFontFormat(name);
    await Excel.run((context) => {
      const sheet = context.workbook.worksheets.getItem(name);
      const range = sheet.getRangeByIndexes(0, lastCol, 1, this.columns.length);
      range.values = [this.columns.map((c) => c.header)];
      range.format.autofitColumns();
      range.format.fill.color = "#efd9b4";
      range.format.font.bold = false;
      range.format.font.name = fontName;
      range.format.font.size = size;

      return context.sync();
    });
  }

  private  processAppendRows(resp: PersonatorInfoResponse, requestIndex: number) {
    const { name, lastCol } = this.sheet;
    const { Records } = resp;
    const startRow = requestIndex * RECORDS_PER_BATCH + 1;
    return Excel.run(async (context) => {
      let outputValues: any[][] = [];
      let columnFields = this.columns.map((c) => c.field);
      const sheet = context.workbook.worksheets.getItem(name);
      const range = sheet.getRangeByIndexes(startRow, lastCol, Records.length, this.columns.length);
      const { name: fontName, size } = await getDefaultFontFormat(name);

      outputValues = Records.map((r) => {
        this.accumulateDemographicCreditStatistics(r);
        return columnFields.map((col) => {
          if (col === "Results") {
            this.accumulateCreditStatistics(
              this.formValues.processingMode,
              getStatisticalResults(r[col] || "", this.formValues.cbOutGeoLocation?.length > 0)
            );
            ReportService.instance.accumulateResultCodeStatistics(r[col] || "");
          }
          return r[col] || "";
        });
      });

      range.values = outputValues;
      range.format.autofitColumns();
      range.format.font.name = fontName;
      range.format.font.size = size;
      return context.sync();
    });
  }

  private async cloneSheet() {
    await Excel.run(async (context) => {
      const { name } = this.sheet;
      const sheet = context.workbook.worksheets.getItem(name);
      const newSheet = sheet.copy("After", sheet);
      newSheet.activate();

      newSheet.load("name");
      await context.sync();
      this.sheet.name = newSheet.name;
      return context.sync();
    });
  }

  private async accoumulateRequests() {
    return Excel.run(async (context) => {
      const { name, totalRows, lastCol } = this.sheet;
      let requests: Array<() => Promise<any>> = [];
      let requestIndexer = -1;
      const sheet = context.workbook.worksheets.getItem(name);
      const range = sheet.getRangeByIndexes(1, 0, totalRows, lastCol);
      range.load("values");

      const fieldMaps = InputFields.filter((field) => !!this.formValues[field]).map((field) => ({
        field,
        colIndex: this.formValues[field],
      }));

      await context.sync();

      while (range.values.length) {
        requestIndexer++;
        const recordPerRequest: Partial<PersonatorRequestRecord>[] = range.values.splice(0, BATCH_SIZE).map((row) =>
          fieldMaps.reduce((record, { field, colIndex }) => {
            if (row[colIndex]) record[field] = row[colIndex];
            return record;
          }, {})
        );
        ReportService.instance.accumulateInputFieldStatistics(recordPerRequest);
        requests.push(this.createRequest(requestIndexer, recordPerRequest));
      }
      return requests;
    });
  }

  private createRequest(requestIndex: number, records: Partial<PersonatorRequestRecord>[]) {
    const reqBody = {
      CustomerID: this.token,
      Actions: this.formValues.operations.join(","),
      Columns: this.columns.map((c) => c.field).join(","),
      TransmissionReference: `mdSrc:{ product: "${PRODUCT}"; version: "${APP_VERSION}"}`,
      Records: records,
      Options: Options.join(";"),
    };
    return () =>
      Request.post<PersonatorInfoResponse>(URL.personatorURL, reqBody).then(({ data }) =>
        this.processAppendRows(data, requestIndex)
      );
  }

  private accumulateCreditStatistics(prodModes: eProcessingModes, results: string) {
    for (let i = 0; i < CreditReportingInfos.length; i++) {
      if ((prodModes & CreditReportingInfos[i].processingMode) != eProcessingModes.None) {
        if (CreditReportingInfos[i].chargeResultCodes == null && CreditReportingInfos[i].noChargeResultCodes == null) {
          this.creditStatistics[CreditReportingInfos[i].infoPackage].count++;
        } else {
          let charge: boolean = CreditReportingInfos[i].chargeResultCodes == null;
          let noCharge = false;

          for (let j = 0; !charge && j < CreditReportingInfos[i].chargeResultCodes.length; j++)
            if (results.includes(CreditReportingInfos[i].chargeResultCodes[j])) charge = true;
          for (
            let j = 0;
            !noCharge &&
            CreditReportingInfos[i].noChargeResultCodes != null &&
            j < CreditReportingInfos[i].noChargeResultCodes.length;
            j++
          )
            if (results.includes(CreditReportingInfos[i].noChargeResultCodes[j])) noCharge = true;
          if (charge && !noCharge) this.creditStatistics[CreditReportingInfos[i].infoPackage].count++;
        }
      }
    }
  }

  private incrementCreditStatistic(procMode: eProcessingModes) {
    for (let i = 0; i < CreditReportingInfos.length; i++) {
      if ((this.creditStatistics[i].processingMode & procMode) != 0) {
        this.creditStatistics[i].count++;
        return;
      }
    }
  }

  private demographicPresent(demographic: string) {
    return demographic != null && demographic.trim() != "" && demographic !== "Unknown";
  }

  private accumulateDemographicCreditStatistics(record: PersonatorResponseRecord) {
    const { processingMode, cbDemographics } = this.formValues;
    if (
      (processingMode & eProcessingModes.USDemographicGender) != eProcessingModes.None &&
      !record.DemographicsResults &&
      (record.DemographicsResults.includes("GD01") || record.DemographicsResults.includes("GD02"))
    )
      this.incrementCreditStatistic(eProcessingModes.USDemographicGender);

    if (cbDemographics.includes("DateOfBirth") && this.demographicPresent(record.DateOfBirth))
      this.incrementCreditStatistic(eProcessingModes.USDemographicDateOfBirth);
    if (cbDemographics.includes("DateOfDeath") && this.demographicPresent(record.DateOfDeath))
      this.incrementCreditStatistic(eProcessingModes.USDemographicDateOfDeath);
    if (cbDemographics.includes("HouseholdIncome") && this.demographicPresent(record.HouseholdIncome))
      this.incrementCreditStatistic(eProcessingModes.USDemographicHouseholdIncome);
    if (cbDemographics.includes("OwnRent") && this.demographicPresent(record.OwnRent))
      this.incrementCreditStatistic(eProcessingModes.USDemographicResidenceType);
    if (cbDemographics.includes("LengthOfResidence") && this.demographicPresent(record.LengthOfResidence))
      this.incrementCreditStatistic(eProcessingModes.USDemographicLengthOfResidence);
    if (cbDemographics.includes("Occupation") && this.demographicPresent(record.Occupation))
      this.incrementCreditStatistic(eProcessingModes.USDemographicOccupation);
    if (cbDemographics.includes("MaritalStatus") && this.demographicPresent(record.MaritalStatus))
      this.incrementCreditStatistic(eProcessingModes.USDemographicMaritalStatus);
    if (cbDemographics.includes("PresenceOfChildren") && this.demographicPresent(record.PresenceOfChildren))
      this.incrementCreditStatistic(eProcessingModes.USDemographicChildren);
    if (cbDemographics.includes("Education") && this.demographicPresent(record.Education))
      this.incrementCreditStatistic(eProcessingModes.USDemographicEducation);
    if (cbDemographics.includes("HouseholdSize") && this.demographicPresent(record.HouseholdSize))
      this.incrementCreditStatistic(eProcessingModes.USDemographicHouseholdSize);
    if (cbDemographics.includes("PoliticalParty") && this.demographicPresent(record.PoliticalParty))
      this.incrementCreditStatistic(eProcessingModes.USDemographicPoliticalParty);
    if (cbDemographics.includes("PresenceOfSenior") && this.demographicPresent(record.PresenceOfSenior))
      this.incrementCreditStatistic(eProcessingModes.USDemographicPresenceOfSenior);
    if (cbDemographics.includes("CreditCardUser") && this.demographicPresent(record.CreditCardUser))
      this.incrementCreditStatistic(eProcessingModes.USDemographicCreditCardUser);
    if (cbDemographics.includes("EthnicCode") && this.demographicPresent(record.EthnicCode))
      this.incrementCreditStatistic(eProcessingModes.USDemographicEthnicCode);
    if (cbDemographics.includes("EthnicGroup") && this.demographicPresent(record.EthnicGroup))
      this.incrementCreditStatistic(eProcessingModes.USDemographicEthnicGroup);
    if (cbDemographics.includes("ChildrenAgeRange") && this.demographicPresent(record.ChildrenAgeRange))
      this.incrementCreditStatistic(eProcessingModes.USDemographicChildrenAgeRange);
  }
}
