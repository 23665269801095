import * as React from "react";
import { createRoot } from "react-dom/client";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import "./commands/commands";
import "./index.css";
import App from "./App";
import { Toaster, RouterUtil } from "./utilities/helpers";

const rootElement: HTMLElement = document.getElementById("container");
const root = createRoot(rootElement);

/* Render application after Office initializes */
Office.onReady(() => {
  Office?.addin?.onVisibilityModeChanged((r) => {
    if (r.visibilityMode === "Hidden") {
      Toaster.instance.emit("toast", {});
      RouterUtil.instance.push('/hidden');
    }
  });
  
  root.render(
    <FluentProvider theme={webLightTheme}>
      <App />
    </FluentProvider>
  );
});

if ((module as any).hot) {
  (module as any).hot.accept("./App.tsx", () => {
    const NextApp = require("./App").default;
    root.render(NextApp);
  });
}
