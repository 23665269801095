import { COLORS, CreditReportingInfos, STATUS_CODE } from "../utilities/Constants";
import { eProcessingModes } from "../utilities/models";
export class ReportService {
  private static _instance: ReportService;
  results: string[];
  report: ResultStatistics;

  static get instance() {
    return this._instance || (this._instance = new this());
  }

  constructor() {
    this.results = [];
    this.report = {
      Appended: {
        Name: { Count: 0, BgColor: "#f0f8ff" },
        Address: { Count: 0, BgColor: "#ffe4e1" },
        Phone: { Count: 0, BgColor: "#98fb98" },
        Email: { Count: 0, BgColor: "#f0ffff" },
        Company: { Count: 0 },
      },
      Bad: {
        Name: { Count: 0, Color: "#ffffff", BgColor: "#4682b4" },
        Address: { Count: 0, BgColor: "#dc143c", Color: "#ffffff" },
        Phone: { Count: 0 },
        Email: { Count: 0 },
        Company: { Count: 0 },
      },
      Corrected: {
        Name: { Count: 0, BgColor: "#e6e6fa" },
        Address: { Count: 0, BgColor: "#db7093", Color: "#ffffff" },
        Phone: { Count: 0 },
        Email: { Count: 0 },
        Company: { Count: 0 },
      },
      Empty: {
        Name: { Count: 0 },
        Address: { Count: 0 },
        Phone: { Count: 0 },
        Email: { Count: 0 },
        Company: { Count: 0 },
      },
      Good: {
        BgColor: "#0078d7",
        Color: "#ffffff",
        Name: { Count: 0 },
        Address: { Count: 0 },
        Phone: { Count: 0 },
        Email: { Count: 0 },
        Company: { Count: 0 },
      },
      Moved: {
        Name: { Count: 0 },
        Address: { Count: 0, BgColor: "#ffb6c1", Color: "#ffffff" },
        Phone: { Count: 0 },
        Email: { Count: 0 },
        Company: { Count: 0 },
      },
      Verified: {
        Name: { Count: 0 },
        Address: { Count: 0 },
        Phone: { Count: 0 },
        Email: { Count: 0 },
        Company: { Count: 0 },
      },
    };
  }

  accumulateResultCodeStatistics(results: string) {
    if (!results) return;
    this.collectResult(results);
    const verifyResults = this.getVerifyResults(results);
    const appendResults = this.getAppendResults(results);

    // #region Collect Name Statistics
    if (results.includes("NE")) {
      this.report.Bad.Name.Count++;
    } else if (this.isContainResult(results, ["NS03", "NS04"])) {
      this.report.Good.Name.Count++;
      this.report.Corrected.Name.Count++;
    } else if (results.includes("NS")) {
      this.report.Good.Name.Count++;
    }

    if (this.isContainResult(verifyResults, ["VR01", "VR02", "VR03"])) {
      this.report.Verified.Name.Count++;
    }

    if (appendResults.includes("DA10")) {
      if (this.report.Empty.Name.Count > 0) {
        this.report.Empty.Name.Count--;
      }
      this.report.Appended.Name.Count++;
    }
    // #endregion

    // #region Collect Company Statistics
    if (this.isContainResult(verifyResults, ["VR07", "VR08", "VR09", "VR10"])) {
      this.report.Verified.Company.Count++;
    }

    if (appendResults.includes("DA20")) {
      if (this.report.Empty.Company.Count > 0) {
        this.report.Empty.Company.Count--;
      }
      this.report.Appended.Company.Count++;
      this.report.Good.Company.Count++;
    }
    // #endregion

    // #region Collect Address Statistics
    if (this.isContainResult(results, ["AE", "AS12"])) {
      this.report.Bad.Address.Count++;
    } else if (results.includes("AC") && !results.includes("AS12")) {
      this.report.Good.Address.Count++;
      this.report.Corrected.Address.Count++;
    } else if (this.isContainResult(results, ["AS", "AS12"])) {
      this.report.Good.Address.Count++;
    }

    if (this.isContainResult(verifyResults, ["VR01", "VR04", "VR05", "VR07"])) this.report.Verified.Address.Count++;

    if (results.includes("AS12")) this.report.Moved.Address.Count++;

    if (appendResults.includes("DA00")) {
      if (this.report.Empty.Address.Count > 0) {
        this.report.Empty.Address.Count--;
      }
      this.report.Appended.Address.Count++;
    }
    // #endregion

    // #region Collect Phone Statistics
    if (results.includes("PE")) this.report.Bad.Phone.Count++;
    else if (this.isContainResult(results, ["PS03", "PS06"])) this.report.Corrected.Phone.Count++;
    else if (results.includes("PS")) this.report.Good.Phone.Count++;

    if (this.isContainResult(verifyResults, ["VR02", "VR04", "VR08"])) this.report.Verified.Phone.Count++;

    if (appendResults.includes("DA30")) {
      if (this.report.Empty.Phone.Count > 0) {
        this.report.Empty.Phone.Count--;
      }
      this.report.Appended.Phone.Count++;
    }
    // #endregion

    // #region Collect Email Statistics
    if (results.includes("EE")) {
      this.report.Bad.Email.Count++;
    } else if (this.isContainResult(results, ["ES10", "ES11", "ES12", "ES13"])) {
      this.report.Good.Email.Count++;
      this.report.Corrected.Email.Count++;
    } else if (results.includes("ES")) {
      this.report.Good.Email.Count++;
    }

    if (this.isContainResult(verifyResults, ["VR03", "VR05", "VR09", "VR10"])) this.report.Verified.Email.Count++;

    if (appendResults.includes("DA40")) {
      if (this.report.Empty.Email.Count > 0) {
        this.report.Empty.Email.Count--;
      }
      this.report.Appended.Email.Count++;
    }
    // #endregion
  }

  getVerifyResults(results: string): string {
    if (!results) return "";
    const codes: string[] = results.split(",");
    const verifyCodes = [
      "VR01",
      "VR02",
      "VR03",
      "VR04",
      "VR05",
      "VR06",
      "VR07",
      "VR08",
      "VR09",
      "VR10",
      "VS00",
      "VS01",
      "VS02",
      "VS12",
      "VS13",
      "VS22",
      "VS30",
      "VS31",
      "VS40",
      "VS41",
    ];
    return codes.filter((c) => verifyCodes.includes(c)).join(",");
  }

  getAppendResults(results: string) {
    if (!results) return "";
    const codes: string[] = results.split(",");
    const appendCodes = ["DA00", "DA10", "DA20", "DA30", "DA40"];
    return codes.filter((c) => appendCodes.includes(c)).join(",");
  }

  private isContainResult(results: string, codes: string[]): boolean {
    if (!results || codes.length == 0) return false;
    return codes.some((code) => results.includes(code));
  }

  accumulateInputFieldStatistics(records: Partial<PersonatorRequestRecord>[]) {
    records.forEach((record) => {
      if (!record?.FullName && !record?.FirstName && !record?.LastName) this.report.Empty.Name.Count++;
      // if (!record?.CompanyName) {
      // 	this.report.Empty.Company.Count++;
      // } else this.report.Good.Company.Count++;

      if (!record?.AddressLine1 && !record?.AddressLine2 && !record?.City && !record?.State && !record?.PostalCode)
        this.report.Empty.Address.Count++;

      if (!record?.PhoneNumber) this.report.Empty.Phone.Count++;
      if (!record?.EmailAddress) this.report.Empty.Email.Count++;
    });
  }

  collectResult(results: string) {
    this.results = [...this.results, ...results.split(",")];
  }

  getResultCount(totalRows: number) {
    const count = this.results.reduce((cnt, cur) => ((cnt[cur] = cnt[cur] + 1 || 1), cnt), {});

    return Object.entries(count)
      .filter(([code]) => code)
      .map(([code, count]: any | number[]) => {
        return {
          code,
          count,
          description: `${code} : ${STATUS_CODE[code] || "Unknown"}`,
          percentage: `${Math.round((count / totalRows) * 100)}%`,
          color: this.getColor(code),
        };
      })
      .sort((_a, _b) => {
        if (_a.code > _b.code) return 1;
        if (_a.code < _b.code) return -1;
        return 0;
      });
  }

  createResultStatistics(sheet: Excel.Worksheet) {
    const headers: OperatorTypes[] = ["Address", "Company", "Email", "Name", "Phone"];

    return Excel.run((context) => {
      const range = sheet.getRangeByIndexes(1, 5, 8, headers.length + 1);
      const data: any[][] = [["", ...headers]];

      ["EdgeLeft", "EdgeRight", "EdgeBottom", "EdgeTop"].forEach((edge: any) => {
        range.format.borders.getItem(edge).style = "Continuous";
        range.format.borders.getItem(edge).weight = "Medium";
      });
      range.format.borders.getItem("InsideHorizontal").style = "Continuous";
      range.format.borders.getItem("InsideVertical").style = "Continuous";

      const headerRange = sheet.getRangeByIndexes(1, 5, 1, headers.length + 1);
      headerRange.format.horizontalAlignment = "Center";

      Object.entries(this.report)
        .sort(([k1], [k2]) => {
          if (k1 > k2) return 1;
          if (k1 < k2) return -1;
          return 0;
        })
        .forEach(([key, value], index) => {
          const range = sheet.getRangeByIndexes(index + 2, 5, 1, 1);
          if (!!value?.BgColor) range.format.fill.color = value.BgColor;
          if (!!value?.Color) range.format.font.color = value.Color;

          data.push([
            key,
            ...headers.map((h, idx) => {
              const cell = sheet.getRangeByIndexes(index + 2, 6 + idx, 1, 1);
              cell.format.horizontalAlignment = "Center";
              if (!!value[h]?.BgColor) cell.format.fill.color = value[h].BgColor;
              if (!!value[h]?.Color) cell.format.font.color = value[h].Color;
              return value[h]?.Count || 0;
            }),
          ]);
        });
      range.values = data;
      range.format.autofitColumns();
      return context.sync();
    });
  }

  async generateReport({ name, totalRows }: ActiveSheet, creditUsage?: { creditsRemaining: number; creditsConsumed: number }): Promise<ReportResult> {
    return Excel.run(async (context) => {
      const workbook = context.workbook;
      const reportSheetName = `${name}${new Date().getTime()}_Report`;
      const sheet = workbook.worksheets.add(reportSheetName);
      const resultCount = this.getResultCount(totalRows);

      const range = sheet.getRangeByIndexes(0, 0, resultCount.length + 2, 4);
      range.values = [
        ["Total Records", totalRows, "", ""],
        ["Result Code", "Count", "Percentage", "Description"],
        ...resultCount.map(({ code, count, percentage, description }) => [code, count, percentage, description]),
      ];

      // set formats
      const totalRecordTitleRange = sheet.getRangeByIndexes(0, 0, 1, 1);
      totalRecordTitleRange.format.font.bold = true;

      const headerRange = sheet.getRangeByIndexes(1, 0, 1, 4);
      headerRange.format.fill.color = "#efd9b4";
      headerRange.format.font.bold = true;
      range.format.autofitColumns();

      if (resultCount.length) {
        const percentColRange = sheet.getRangeByIndexes(2, 2, resultCount.length, 1);
        percentColRange.format.horizontalAlignment = "Center";

        for (var i = 0; i < resultCount.length; i++) {
          const r = sheet.getRangeByIndexes(i + 2, 0, 1, 1);
          r.format.fill.color = resultCount[i]?.color || COLORS.DEFAULT
        }
      }

      await this.createResultStatistics(sheet);

      await context.sync();

      return {
        reportSheetName,
        creditsConsumed: creditUsage.creditsConsumed ?? 0,
        creditsRemaining: creditUsage.creditsRemaining ?? 0,
        results: Object.entries(this.report).map(([key, value]) => ({
          key,
          ...value,
        })),
      };
    });
  }

  getColor(code: string) {
    if (code.startsWith("AS") || code.startsWith("VR") || code.startsWith("VS")) return COLORS.GOOD;
    if (code.startsWith("AC")) return COLORS.QUITE_GOOD;
    if (code.includes("E")) return COLORS.BAD;
    return COLORS.DEFAULT;
  }
}
