import { PackageInfoRecord, eProcessingModes } from "../models";

export const PACKAGE_RECORDS: PackageInfoRecord[] = [
    new PackageInfoRecord("2700", "pkgBorgCheck", "pkgBorgCheck", eProcessingModes.USCheck),
    new PackageInfoRecord("2701", "pkgBorgVerify", "pkgBorgVerify", eProcessingModes.USVerify),
    new PackageInfoRecord("2702", "pkgBorgMoveUpdate", "pkgBorgMoveUpdate", eProcessingModes.USMove),
    new PackageInfoRecord("2734", "pkgBorgGeoCode", "pkgBorgGeoCode", eProcessingModes.USGeoCode),
    new PackageInfoRecord("2703", "pkgBorgGeoPoint", "pkgBorgGeoPoint", eProcessingModes.USGeoPoint),
    new PackageInfoRecord("2704", "pkgBorgAppend", "pkgBorgAppend", eProcessingModes.USAppendName),
    new PackageInfoRecord("2705", "pkgBorgAppend", "pkgBorgAppend", eProcessingModes.USAppendAddress),
    new PackageInfoRecord("2706", "pkgBorgAppend", "pkgBorgAppend", eProcessingModes.USAppendPhone),
    new PackageInfoRecord("2707", "pkgBorgAppend", "pkgBorgAppend", eProcessingModes.USAppendEmail),
    new PackageInfoRecord("2710", "pkgBorgDemographics", "pkgBorgDemographics", eProcessingModes.USDemographicDateOfBirth),
    new PackageInfoRecord("2711", "pkgBorgDemographics", "pkgBorgDemographics", eProcessingModes.USDemographicDateOfDeath),
    new PackageInfoRecord("2712", "pkgBorgDemographics", "pkgBorgDemographics", eProcessingModes.USDemographicGender),
    new PackageInfoRecord("2713", "pkgBorgDemographics", "pkgBorgDemographics", eProcessingModes.USDemographicHouseholdIncome),
    new PackageInfoRecord("2714", "pkgBorgDemographics", "pkgBorgDemographics", eProcessingModes.USDemographicResidenceType),
    new PackageInfoRecord("2715", "pkgBorgDemographics", "pkgBorgDemographics", eProcessingModes.USDemographicLengthOfResidence),
    new PackageInfoRecord("2716", "pkgBorgDemographics", "pkgBorgDemographics", eProcessingModes.USDemographicOccupation),
    new PackageInfoRecord("2717", "pkgBorgDemographics", "pkgBorgDemographics", eProcessingModes.USDemographicMaritalStatus),
    new PackageInfoRecord("2718", "pkgBorgDemographics", "pkgBorgDemographics", eProcessingModes.USDemographicChildren),
    new PackageInfoRecord("2740", "pkgBorgDemographics", "pkgBorgDemographics", eProcessingModes.USDemographicEducation),
    new PackageInfoRecord("2741", "pkgBorgDemographics", "pkgBorgDemographics", eProcessingModes.USDemographicHouseholdSize),
    new PackageInfoRecord("2742", "pkgBorgDemographics", "pkgBorgDemographics", eProcessingModes.USDemographicPoliticalParty),
    new PackageInfoRecord("2743", "pkgBorgDemographics", "pkgBorgDemographics", eProcessingModes.USDemographicPresenceOfSenior),
    new PackageInfoRecord("2744", "pkgBorgDemographics", "pkgBorgDemographics", eProcessingModes.USDemographicCreditCardUser),
    new PackageInfoRecord("2728", "pkgBorgDemographics", "pkgBorgDemographics", eProcessingModes.USDemographicEthnicCode),
    new PackageInfoRecord("2729", "pkgBorgDemographics", "pkgBorgDemographics", eProcessingModes.USDemographicEthnicGroup),
    new PackageInfoRecord("2719", "pkgBorgDemographics", "pkgBorgDemographics", eProcessingModes.USDemographicChildrenAgeRange),

    new PackageInfoRecord("7744", "pkgPropertyDetail", "pkgPropertyDetail", eProcessingModes.USProperty),
    new PackageInfoRecord("2708", "pkgIntAddressCheck", "pkgIntAddressCheck", eProcessingModes.IntCheck),
    new PackageInfoRecord("2735", "pkgIntGeoCode", "pkgIntGeoCode", eProcessingModes.IntGeoCode),
    new PackageInfoRecord("2709", "pkgIntGeoCode", "pkgIntGeoCode", eProcessingModes.IntGeoPoint),
    new PackageInfoRecord("2730", "pkgSmartMoverCheck", "pkgSmartMoverCheck", eProcessingModes.SmartMoverCheck),
    new PackageInfoRecord("2731", "pkgSmartMover", "pkgSmartMover", eProcessingModes.SmartMoverNCOA),
    new PackageInfoRecord("2732", "pkgCCOASmartMover", "pkgCCOASmartMover", eProcessingModes.SmartMoverCCOA),
    new PackageInfoRecord("2773", "pkgBusinessCoder", "pkgBusinessCoder", eProcessingModes.BusinessCoder_Listware),
    new PackageInfoRecord("2774", "pkgBusinessCoder", "pkgBusinessCoder", eProcessingModes.BusinessCoder_AddressValidate),
    new PackageInfoRecord("2775", "pkgBusinessCoder", "pkgBusinessCoder", eProcessingModes.BusinessCoder_GeoPoint),
    new PackageInfoRecord("2776", "pkgBusinessCoder", "pkgBusinessCoder", eProcessingModes.BusinessCoder_FirmgraphicsAppend),

    new PackageInfoRecord("2737", "pkgMatchUp", "pkgMatchUp", eProcessingModes.MatchUpCheck),
    new PackageInfoRecord("2738", "pkgMatchUp", "pkgMatchUp", eProcessingModes.MatchUpDedupe),
    new PackageInfoRecord("2699", "pkgExpressEntry", "pkgExpressEntry", eProcessingModes.ExpressEntry),

    //Uses the same number and packages as the ReverseGeo service uses
    new PackageInfoRecord("2753", "pkgReverseGeo,pkgReverseGeo_GeoCoder", "pkgReverseGeo,pkgReverseGeo_GeoCoder", eProcessingModes.ReverseGeo),
]