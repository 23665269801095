import React from "react";
import clsx from "clsx";
import { Spinner, SpinnerProps } from "@fluentui/react-components";

type Background = "dark" | "light";
type Opacity = 0.5 | 0.6 | 0.7 | 1;

interface LoaderProps extends SpinnerProps {
  visible?: boolean;
  full?: boolean;
  backgroundColor?: Background;
  opacity?: Opacity;
}

const backgrounds: Record<string, string> = {
  dark: "bg-slate-950",
  light: "bg-white",
};

export default function Loader(props: LoaderProps) {
  const { full, backgroundColor = "light", opacity = 1, size = "small", visible = true } = props;
  if (!visible) return null;
  
  return (
    <div
      className={clsx(
        "z-50",
        backgrounds[backgroundColor],
        full && "absolute flex items-center justify-center top-0 bottom-0 left-0 right-0",
        `opacity-[${opacity}]`
      )}
    >
      <Spinner {...props} size={size} />
    </div>
  );
}
