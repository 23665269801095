export const STATUS_CODE: Record<string, string> = {
  AS01: "Valid Address",
  AS02: "Street Only Match",
  AS03: "Non USPS Address Match",
  AS09: "Foreign Address",
  AS10: "CMRA Address",
  AS11: "PBSA Address",
  AS12: "Moved to New Address",
  AS13: "Address Updated By LACS",
  AS14: "Suite Appended",
  AS15: "Apartment Appended",
  AS16: "Vacant Address",
  AS17: "No USPS Mail Delivery",
  AS20: "Deliverable only by USPS",
  AS23: "Extraneous Information",
  AS24: "USPS Door Not Accessible",
  AE01: "Postal Code Error/General Error",
  AE02: "Unknown Street",
  AE03: "Component Mismatch Error",
  AE04: "Non-Deliverable Address",
  AE05: "Multiple Match",
  AE06: "Early Warning System",
  AE07: "Missing Minimum Address",
  AE08: "Sub Premise Number Invalid",
  AE09: "Sub Premise Number Missing",
  AE10: "Premise Number Invalid",
  AE11: "Premise Number Missing",
  AE12: "Box Number Invalid",
  AE13: "Box Number Missing",
  AE14: "PMB Number Missing",
  AE17: "Sub Premise Not Required (Deprecated - See AS23)",
  AE21: "MAK Not Found",
  AC01: "Postal Code Change",
  AC02: "Administrative Area Change",
  AC03: "Locality Change",
  AC04: "Alternate to Base Change",
  AC05: "Alias Name Change",
  AC06: "Address1/Address2 Swap",
  AC07: "Address1 & Company Swapped",
  AC08: "Plus4 Change",
  AC09: "Dependent Locality Change",
  AC10: "Thoroughfare Name Change",
  AC11: "Thoroughfare Type Change",
  AC12: "Thoroughfare Directional Change",
  AC13: "Sub Premise Type Change",
  AC14: "Sub Premise Number Change",
  AC20: "House Number Change",
  ES01: "Valid Email",
  ES02: "Invalid Email (Deprecated)",
  ES03: "Unknown Email",
  ES04: "Mobile Email Address",
  ES05: "Disposable Domain",
  ES06: "Spamtrap Domain",
  ES07: "Accept All Server",
  ES10: "Syntax Changed",
  ES11: "Top Level Domain Changed",
  ES12: "Domain Changed (Spelling)",
  ES13: "Domain Changed (Update)",
  ES21: "Verify (Precision: Cached Mailbox Result)",
  EE01: "Email Syntax Error",
  EE02: "Email Domain Not Found",
  EE03: "Email Server Not Found",
  EE04: "Invalid Email",
  GS01: "Geocoded to Street Level",
  GS03: "Geocoded to Community Level",
  GS05: "Geocoded to Rooftop Level",
  GS06: "Geocoded to Interpolated Rooftop Level",
  GS10: "Wire Center Lat/Long",
  GE01: "Invalid Postal Code",
  GE02: "Postal Code Coordinates Not Found in Database",
  NS01: "Name Parsed",
  NS02: "Error Parsing",
  NS03: "First Name Spelling Corrected",
  NS04: "First Name 2 Spelling Corrected",
  NS05: "First Name 1 Found",
  NS06: "Last Name 1 Found",
  NS07: "First Name 2 Found",
  NS08: "Last Name 2 Found",
  NE01: "Unrecognized Format",
  NE02: "Multiple First Names Detected",
  NE03: "Vulgarity Detected",
  NE04: "Suspicious Word Detected",
  NE05: "Company Name Detected",
  NE06: "Non-Alphabetic Character Detected",
  DA00: "Address Appended",
  DA01: "City/State Append from Phone",
  DA10: "Name Appended",
  DA20: "Company Appended",
  DA30: "Phone Appended",
  DA40: "Email Appended",
  VR01: "Name and Address Match",
  VR02: "Name and Phone Match",
  VR03: "Name and Email Match",
  VR04: "Address and Phone Match",
  VR05: "Address and Email Match",
  VR06: "Phone and Email Match",
  VR07: "Company and Address Match",
  VR08: "Company and Phone Match",
  VR09: "Company and Email Match",
  VR10: "Company and Name Match",
  VR11: "Name and SSN Match",
  VS00: "Contact Record Not Found",
  VS01: "Historical Address Match",
  VS02: "Partial Address Match",
  VS12: "Last Name Match",
  VS13: "First Name Match",
  VS22: "Partial Company Name Match",
  VS30: "Phone Not Found",
  VS31: "Historical Phone Match",
  VS40: "Email Not Found",
  VS41: "Historical Email Address",
  VS50: "Last4 Only Match",
  PS01: "Valid Phone",
  PS02: "7-Digit Match",
  PS03: "Corrected Area Code",
  PS06: "Updated Area Code",
  PS07: "Cellular Line",
  PS08: "Land Line",
  PS09: "VOIP Line",
  PS10: "Residential Number",
  PS11: "Business Number",
  PS12: "SOHO Number",
  PE01: "Invalid Phone",
  PE02: "Blank Phone",
  PE03: "Bad Phone",
  PE04: "Multiple Match",
  PE05: "Bad Prefix/Prefix +1",
  SN01: "Invalid SSN Format",
  SP01: "No Action",
  ID01: "$0-$15,000",
  ID02: "$15,001-$20,000",
  ID03: "$20,001-$30,000",
  ID04: "$30,001-$40,000",
  ID05: "$40,001-$50,000",
  ID06: "$50,001-$60,000",
  ID07: "$60,001-$75,000",
  ID08: "$75,001-$100,000",
  ID09: "$100,001-$125,000",
  ID10: "$125,001-$150,000",
  ID11: "$150,001+",
  LD00: "Less than 1 year",
  LD01: "1-2 years",
  LD02: "2-3 years",
  LD03: "3-4 years",
  LD04: "4-5 years",
  LD05: "5-6 years",
  LD06: "6-7 years",
  LD07: "7-8 years",
  LD08: "8-9 years",
  LD09: "9-10 years",
  LD10: "10-11 years",
  LD11: "11-12 years",
  LD12: "12-13 years",
  LD13: "13-14 years",
  LD14: "14-15 years",
  LD15: "15+ years",
  CD01: "No Children Present",
  CD02: "Children Present",
  MD01: "Definitely Single",
  MD02: "Inferred Single",
  MD03: "Inferred Married",
  MD04: "Definitely Married",
  DD00: "Not Deceased",
  DD01: "Deceased",
  GD01: "Male",
  GD02: "Female",
  GD03: "Neutral",
  RD01: "Definite Renter",
  RD02: "Inferred Renter",
  RD03: "Inferred Owner",
  RD04: "Definite Owner",
  WD01: "Physician/Dentist",
  WD02: "Healthcare",
  WD03: "Lawyer/Judge",
  WD04: "Professional/Technical",
  WD05: "Management",
  WD06: "Teacher/Educator",
  WD07: "Sales/Marketing",
  WD08: "Clerical/Service Worker",
  WD09: "Tradesmen/Laborer",
  WD10: "Farmer",
  WD11: "Student",
  WD12: "Homemaker",
  WD13: "Retired",
  WD14: "Federal Employee",
  WD15: "Military",
  WD16: "Military Retired",
  WD17: "Business Owner",
  WD18: "Religious",
  WD19: "Self Employed",
  WD20: "Financial",
  WD99: "Other",
  A: "Alias",
  F: "Firm or Company",
  G: "General Delivery",
  H: "Highrise or Business Complex",
  M: "Military Address",
  P: "PO Box",
  R: "Rural Route",
  S: "Street or Residential",
  U: "Unique/LVR",
  "1": "Street",
  "2": "Street Served by Route and GD",
  "3": "Lock Box",
  "4": "Route Service",
  "5": "General Delivery",
  B: "LVR Street",
  C: "Government Street",
  D: "LVR Lock Box",
  E: "Government Lock Box",
  L: "LVR General Delivery",
  K: "Building",
  SE01: "Cloud Service Internal Error",
  GW01: "Expiring License",
  GW11: "Option Name Error",
  GW12: "Option Value Error",
  GE03: "Records Per Request Exceeded",
  GE04: "Empty License Key",
  GE05: "Invalid License Key",
  GE06: "Disabled License Key",
  GE07: "Invalid Request",
  GE08: "Product/Level Not Enabled",
  GE09: "Customer Does Not Exist",
  GE10: "Customer License Disabled",
  GE11: "Customer Disabled",
  GE12: "IP Blacklisted",
  GE13: "IP Not Whitelisted",
  GE14: "Out of Credits",
  GE20: "Verify Not Activated",
  GE21: "Append Not Activated",
  GE22: "Move Not Activated",
  GE23: "No Valid Action Requested",
  GE24: "Demographics Not Activated",
  GE27: "IP Columns Not Activated",
  GE28: "SSN Verification Not Activated",
  GE29: "Not Available for Credit License",
};
