import React from "react";
import { Typography } from "../../components";
import { usePersonatorContext } from "../../state/Personator";
import { Button } from "@fluentui/react-components";
import clsx from "clsx";
import useCustomerLicenseType from "../../hooks/useCustomerLicenseType";
import { formatCredit } from "../../utilities/helpers";

const tableClasses = "border border-gray-800 px-1 text-xs";
const headers = ["", "Address", "Company", "Email", "Name", "Phone"];

export default function Result() {
  const {
    state: { report, sheet },
  } = usePersonatorContext();
  const { usingCredits } = useCustomerLicenseType();
  const { results = [], reportSheetName, creditsConsumed = 0, creditsRemaining = 0 } = report || {};

  const getStyle = (row: { key: string } & OperatorStatistics, property?: OperatorTypes) => {
    let style = {};
    if (!property)
      style = {
        backgroundColor: row.BgColor || "#fff",
        color: row?.Color || "#000",
      };
    else
      style = {
        backgroundColor: row[property]?.BgColor || "#fff",
        color: row[property]?.Color || "#000",
      };

    return {
      style,
    };
  };

  const onClose = async () => {
    await Office.addin.hide();
  };

  const viewReport = async () => {
    return Excel.run((context) => {
      const sheet = context.workbook.worksheets.getItem(reportSheetName);
      if (sheet) sheet.activate();
      return context.sync();
    });
  };

  return (
    <>
      <Typography variant="h1" block className="text-blue-400">
        Results
      </Typography>
      <Typography block className="mt-2">
        Check the result below based on the count that we're calculating. We're collecting the result codes and counts
        from the Results column.
      </Typography>
      <Typography block className="mt-3">
        Total Records: {sheet?.totalRows || 0}
      </Typography>

      {usingCredits && <>
        <Typography block className="mt-2">
          Consumed Credits: {creditsConsumed}
        </Typography>
        <Typography block className="mt-2">
          Remaining Credits: {formatCredit(creditsRemaining)}
        </Typography>
      </>}

      <table className={clsx("mt-3", tableClasses)}>
        <thead>
          <tr>
            {headers.map((h, idx) => (
              <th key={idx} className={tableClasses}>{h}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {results.map((row, idx) => {
            return (
              <tr key={idx}>
                {headers.map((h: OperatorTypes, index) => (
                  <td key={index} {...getStyle(row, h)} className={clsx(tableClasses, index > 0 ? "text-center" : "text-left")}>
                    {(h ? row[h]?.Count : row.key) || 0}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="fixed bottom-0 right-4 bg-white w-full h-20">
        <div className="flex justify-end gap-3 items-center h-full">
          <Button appearance="primary" onClick={viewReport}>
            View Report
          </Button>
          <Button appearance="primary" onClick={onClose}>
            Close
          </Button>
        </div>
      </div>
    </>
  );
}
