import React, { ReactElement } from "react";
import Tutorial from "./Tutorial";
import CleanOperations from "./Operations";
import InputColumns from "./InputColumns";
import OutputColumns from "./OutputColumns";
import ReviewSetting from "./ReviewSetting";
import { useForm } from "react-hook-form";
import { INPUT_COLS, OPERATIONS, OUTPUT_COLUMNS } from "../../utilities/Constants";
import { DispatchActions, usePersonatorContext } from "../../state/Personator";
import { getAddress, OfficeExcelConfig, Toaster, RouterUtil } from "../../utilities/helpers";
import { PersonatorService } from "../../services/Personator";
import { OfficePersitProperty, PersonatorFormStep, PersonatorFormValues, eProcessingModes } from "../../utilities/models";

export default function usePersonatorHandler({ dialogRef }: any) {
  const timerRef = React.useRef<{ timer?: NodeJS.Timeout; executedTime: number }>({
    timer: null,
    executedTime: 0,
  });
  const [executedTime, setExecutedTime] = React.useState<string>("0 second (s)");
  const {
    state: { step, sheet },
    dispatch,
  } = usePersonatorContext();
  const containerRef = React.useRef<HTMLDivElement>();
  const [formError, setFormError] = React.useState<string>("");

  const form = useForm<PersonatorFormValues>({
    values: {
      operations: [OPERATIONS[0].value],
      name1: [],
      cbOutSalutation: [],
      cbAddress: ["PostalCode", "MelissaAddressKey", "MelissaAddressKeyBase"],
      cbOutGeoLocation: [],
      cbOutPhone: [],
      cbOutEmail: [],
      cbAddressInformation: [],
      cbCensusInformation: [],
      cbDemographics: [],
      cbParsedAddress: [],
      method: "append",
      processingMode: eProcessingModes.USCheck
    },
    mode: "onChange",
  });

  const values = form.watch();
  const tabs: Record<PersonatorFormStep[keyof PersonatorFormStep & number], ReactElement | null> = React.useMemo(
    () => ({
      [PersonatorFormStep.Tutorial]: <Tutorial />,
      [PersonatorFormStep.Operation]: <CleanOperations />,
      [PersonatorFormStep.InputColumn]: <InputColumns />,
      [PersonatorFormStep.OutputColumn]: <OutputColumns />,
      [PersonatorFormStep.ReviewSetting]: <ReviewSetting />,
    }),
    []
  );

  const checkInputValidations = React.useCallback((values: PersonatorFormValues) => {
    let isValid: boolean = false;
    let msg: string = "Address with City + State or Address with ZIP are required.";
    const { AddressLine1, State, City, PostalCode, operations, FullName, FirstName, LastName } = values;
    isValid = !!AddressLine1 && (!!PostalCode || (!!City && !!State));

    if (operations.some((s) => ["Verify", "Move"].includes(s))) {
      isValid = isValid && (!!FullName || (!!FirstName && !!LastName));
      if (!isValid)
        msg =
          "Contact Verify requires Full Name or First Name + Last Name and Address with city, state, or address with ZIP.";
    }

    if (isValid) msg = "";
    setFormError(msg);

    if (!isValid && containerRef.current) {
      setTimeout(() => {
        containerRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
      }, 600);
    }
    return isValid;
  }, []);

  const handleBack = () => {
    if (step == PersonatorFormStep.InputColumn && formError) setFormError("");
    dispatch(DispatchActions.updateFormStep(step - 1));
  };

  const autoFillOutputColumns = React.useCallback((values: PersonatorFormValues) => {
    const { operations } = values;
    const fieldMaps = OUTPUT_COLUMNS.filter((f) => f.mapFrom);

    const groups: Record<any, any[]> = fieldMaps.reduce(function (r, a) {
      r[a.prefix] = r[a.prefix] || [];
      r[a.prefix].push(a);
      return r;
    }, {});

    Object.entries(groups).forEach(([key, o]: any) => {
      let selected = [];
      o.forEach((d) => {
        if (values[d.mapFrom]) {
          selected.push(d.field);
        }
      });
      if (selected.length) form.setValue(key, [...selected, ...values[key]]);
    });

    if (operations.includes("Append"))
      form.setValue("cbOutSalutation", ["Salutation"]);
    
    if (operations.includes("Append") || values.EmailAddress)
      form.setValue("cbOutEmail", ["EmailAddress"]);
    
    if (operations.includes("Append") || values.PhoneNumber) 
      form.setValue("cbOutPhone", ["PhoneNumber"]);
    
  }, []);

  const handleNext = async (values: PersonatorFormValues) => {
    switch (step) {
      case PersonatorFormStep.Tutorial:
        if (values.hideTutorial)
          OfficeExcelConfig.instance.setConfig(OfficePersitProperty.HideTutorial, values.hideTutorial);
        break;
      case PersonatorFormStep.InputColumn:
        if (!checkInputValidations(values)) return;
        autoFillOutputColumns(values);
        break;
      case PersonatorFormStep.ReviewSetting:
        try {
          triggerProcess();
          const result = await PersonatorService.instance.doVerify({ formValues: values, activeSheet: sheet });
          dispatch(DispatchActions.setReportResult(result));
          endProcess();
          Toaster.success("Process data was successful!");
          form.reset();
          dispatch(DispatchActions.updateFormStep(PersonatorFormStep.Tutorial));
          RouterUtil.instance.push("/results");
        } catch (e) {
          Toaster.warn("Opps! Something went wrong!");
          endProcess();
          return;
        }
        break;
    }
    dispatch(DispatchActions.updateFormStep(step + 1));
  };

  const loadActiveSheetData = () => {
    Excel.run(async (context) => {
      try {
        const sheet = context.workbook.worksheets.getActiveWorksheet();
        sheet.load("name");
        const usedRange = sheet.getUsedRange(true).getVisibleView();
        usedRange.load(["columnCount", "rowCount"]);
        await context.sync();

        const lastCol = sheet.getCell(1, usedRange.columnCount - 1);
        lastCol.load(["address", "columnIndex"]);
        await context.sync();

        const formValues = form.getValues();
        const range = sheet.getRange(`A1:${getAddress(lastCol.address)}`);
        range.load(["columnCount", "values"]);
        const propertiesToGet = range.getCellProperties({
          address: true,
        });

        await context.sync();

        const headers: HeaderOption[] = Array(range.columnCount)
          .fill(0)
          .map((_, index) => {
            const cellAddress = propertiesToGet.value[0][index];
            return {
              colIndex: index,
              label: range.values[0][index]?.toString(),
              address: getAddress(cellAddress.address),
            };
          });

        INPUT_COLS.forEach(({ id, key }: any) => {
          const value = headers
            .find((h) =>
              key
                .split(",")
                .map((h) => h.toLowerCase())
                .includes(h.label.toLowerCase())
            )
            ?.colIndex?.toString();
          if (!formValues[id] && value) form.setValue(id, value);
        });

        dispatch(
          DispatchActions.loadSheet({
            name: sheet.name,
            totalRows: usedRange.rowCount - 1,
            lastCol: usedRange.columnCount,
            headers,
          })
        );
      } catch (e) { }
    });
  };

  const triggerProcess = () => {
    dialogRef?.current.trigger(true);
    if (!timerRef?.current.timer) {
      timerRef.current.timer = setInterval(() => {
        timerRef.current.executedTime++;
        let executedTime = (() => {
          if (timerRef.current.executedTime > 60) return `${(timerRef.current.executedTime / 60).toFixed(1)} min (s)`;
          return `${timerRef.current.executedTime} second (s)`;
        })();
        setExecutedTime(executedTime);
      }, 1000);
    }
  };

  const endProcess = () => {
    if (timerRef?.current.timer) {
      clearInterval(timerRef?.current.timer);
      timerRef.current = { timer: null, executedTime: 0 };
    }
    dialogRef?.current.trigger();
  };

  React.useEffect(() => {
    dispatch(DispatchActions.updateFormStep(PersonatorFormStep.Tutorial));
    loadActiveSheetData();
  }, []);

  React.useEffect(() => {
    containerRef?.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }, [containerRef, step]);

  React.useEffect(() => {
    const hideTutorial: boolean = OfficeExcelConfig.instance.getConfig(OfficePersitProperty.HideTutorial);
    if (step == PersonatorFormStep.Tutorial && hideTutorial) dispatch(DispatchActions.updateFormStep(PersonatorFormStep.Operation));
  }, [step]);

  React.useEffect(() => {
    const { processingMode: processingModes, operations, cbOutGeoLocation, cbCensusInformation, cbDemographics } = values;
    let processingMode = processingModes;
    processingMode = (processingMode & eProcessingModes.USDemographicGender);
    processingMode |= (operations.includes('Check') ? eProcessingModes.USCheck : eProcessingModes.None);
    processingMode |= (operations.includes("Verify") ? eProcessingModes.USVerify : eProcessingModes.None);
    processingMode |= (operations.includes("Move") ? eProcessingModes.USMove : eProcessingModes.None);
    processingMode |= (operations.includes("Append") ? eProcessingModes.USAPPEND : eProcessingModes.None);
    
    // for output columns
    processingMode &= ~(eProcessingModes.USGeoCode | eProcessingModes.USGeoPoint);
    processingMode |= ((cbOutGeoLocation?.length || cbCensusInformation?.some(d => [
      "CBSACode",
      "CBSATitle",
      "CBSALevel",
      "CBSADivisionCode",
      "CBSADivisionTitle",
      "CBSADivisionLevel",
      "CountyName",
      "PlaceName",
      "PlaceCode",
      "CountyFIPS",
      "CongressionalDistrict"
    ].includes(d))) ? eProcessingModes.USGeoCode | eProcessingModes.USGeoPoint : eProcessingModes.None);

    processingMode &= ~eProcessingModes.USDEMOGRAPHICS;
    processingMode |= (cbDemographics.includes("DateOfBirth") ? eProcessingModes.USDemographicDateOfBirth : eProcessingModes.None);
    processingMode |= (cbDemographics.includes("DateOfDeath") ? eProcessingModes.USDemographicDateOfDeath : eProcessingModes.None);
    processingMode |= (cbDemographics.includes("HouseholdIncome") ? eProcessingModes.USDemographicHouseholdIncome : eProcessingModes.None);
    processingMode |= (cbDemographics.includes("OwnRent") ? eProcessingModes.USDemographicResidenceType : eProcessingModes.None);
    processingMode |= (cbDemographics.includes("LengthOfResidence") ? eProcessingModes.USDemographicLengthOfResidence : eProcessingModes.None);
    processingMode |= (cbDemographics.includes("Occupation") ? eProcessingModes.USDemographicOccupation : eProcessingModes.None);
    processingMode |= (cbDemographics.includes("MaritalStatus") ? eProcessingModes.USDemographicMaritalStatus : eProcessingModes.None);
    processingMode |= (cbDemographics.includes("PresenceOfChildren") ? eProcessingModes.USDemographicChildren : eProcessingModes.None);
    processingMode |= (cbDemographics.includes("Education") ? eProcessingModes.USDemographicEducation : eProcessingModes.None);
    processingMode |= (cbDemographics.includes("HouseholdSize") ? eProcessingModes.USDemographicHouseholdSize : eProcessingModes.None);
    processingMode |= (cbDemographics.includes("PoliticalParty") ? eProcessingModes.USDemographicPoliticalParty : eProcessingModes.None);
    processingMode |= (cbDemographics.includes("PresenceOfSenior") ? eProcessingModes.USDemographicPresenceOfSenior : eProcessingModes.None);
    processingMode |= (cbDemographics.includes("CreditCardUser") ? eProcessingModes.USDemographicCreditCardUser : eProcessingModes.None);
    processingMode |= (cbDemographics.includes("EthnicCode") ? eProcessingModes.USDemographicEthnicCode : eProcessingModes.None);
    processingMode |= (cbDemographics.includes("EthnicGroup") ? eProcessingModes.USDemographicEthnicGroup : eProcessingModes.None);
    processingMode |= (cbDemographics.includes("ChildrenAgeRange") ? eProcessingModes.USDemographicChildrenAgeRange : eProcessingModes.None);

    form.setValue('processingMode', processingMode);
  }, [values?.operations, values?.cbDemographics, values?.cbCensusInformation, values?.cbOutGeoLocation])

  return {
    step,
    tabs,
    form,
    containerRef,
    handleNext,
    handleBack,
    formError,
    executedTime,
  };
}
