import React, { ReactNode } from "react";
import {
  Dialog as FluentDialog,
  DialogSurface,
  DialogBody,
  DialogContent,
  Field,
  ProgressBar,
} from "@fluentui/react-components";
import { DialogRef } from "./Dialog";

interface ProgressBarProps {
  message?: string;
  children?: ReactNode;
}

export default React.forwardRef<DialogRef, ProgressBarProps>(function ProgressBarModal(props, ref) {
  const [open, setOpen] = React.useState<boolean>(false);
  const trigger = (open?: boolean) => setOpen(!!open);

  React.useImperativeHandle(
    ref,
    () => ({
      trigger,
    }),
    []
  );

  if (!open) return null;

  return (
    <FluentDialog open={open}>
      <DialogSurface>
        <DialogBody>
          <DialogContent>
            <Field validationMessage={props?.message} validationState="none">
              {props.children}
              <ProgressBar shape="rounded" thickness="large" />
            </Field>
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </FluentDialog>
  );
});
