import { PACKAGE_RECORDS } from '../Constants'
import { eProcessingModes } from '../models'

export * from './OfficeExcel'
export * from './RibbonAction'
export * from './Router'
export * from './Toaster'
export * from './Request'


/**
 * formatCredit(n, x)
 * @param integer number: amount
 * @param integer digits: length of decimal
 */
export function formatCredit(number: number = 0, digits: number = 0) {
  return number.toFixed(digits).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const getProcessingModeProdsAndPkgs = (procMode: eProcessingModes): { P: string, K: string } => {
  const pkgsInfo = PACKAGE_RECORDS.filter(pkg => (procMode & pkg.processingMode) != eProcessingModes.None);
  return {
    K: Array.from(new Set(pkgsInfo.map(pkg => pkg.queryPackageId))).join(','),
    P: Array.from(new Set(pkgsInfo.map(pkg => pkg.productId))).join(',')
  }
}

export const productIdToProcessingMode = (prodId: string) => {
  let retVal: eProcessingModes = eProcessingModes.None;
  let prodIds: string[] = prodId?.split(',');
  for (let i = 0; i < prodIds?.length; i++) {
    for (let j = 0; j < PACKAGE_RECORDS.length; j++) {
      if (prodIds[i] === PACKAGE_RECORDS[j]?.productId) {
        retVal |= PACKAGE_RECORDS[j]?.processingMode;
        break;
      }
    }
  }
  return retVal;
}

export const processingModeToSingleQueryPackageIds = (procMode: eProcessingModes) => {
  const pkgs = PACKAGE_RECORDS.filter(pkg => (procMode & pkg?.processingMode) != eProcessingModes.None).map(pkg => {
    let packages = pkg?.queryPackageId.split(',');
    if (packages.length > 0) return packages[0];
    return pkg?.queryPackageId;
  }).join(",")

  return pkgs;
}

export const getStatisticalResults = (results: string, hasGeolocationOutput?: boolean) => {
  let retVal: string = "";

  if (results == null)
    return "";

  let codes: string[] = results.split(',');
  for (let i = 0; i < codes.length; i++)
    if ((codes[i] != "GS01" && codes[i] != "GS05" && codes[i] != "GS06") || hasGeolocationOutput)
      retVal += `${codes[i]},`;

  if (retVal.length > 0)
    retVal = retVal.substring(0, retVal.length - 1);
  return retVal;
}