import React, { Dispatch } from 'react';
import { PersonatorFormStep } from '../utilities/models';

export enum ActionType {
  LOAD_SHEET,
  UPDATE_STEP,
  SET_REPORT_RESULT,
  RESET
};

type Action = {
  type: ActionType.LOAD_SHEET
  payload: ActiveSheet
} | {
  type: ActionType.UPDATE_STEP
  payload: number
} | {
  type: ActionType.SET_REPORT_RESULT
  payload: ReportResult
} | {
  type: ActionType.RESET
  payload?: undefined
}

type PersonatorState = {
  sheet?: ActiveSheet;
  step: number,
  report?: ReportResult
};

interface IContextProps {
  state: PersonatorState;
  dispatch: Dispatch<Action>
}

const initialState: PersonatorState = {
  step: PersonatorFormStep.Tutorial
}

const reducer = (state: PersonatorState, action: Action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionType.LOAD_SHEET:
      return {
        ...state,
        sheet: payload
      }
    case ActionType.UPDATE_STEP:
      return {
        ...state,
        step: payload
      }
    case ActionType.SET_REPORT_RESULT:
      return {
        ...state,
        report: payload
      }
    case ActionType.RESET:
      return initialState
    default:
      return state;
  }
};

const PersonatorContext = React.createContext({} as IContextProps);

const loadSheet = (payload: ActiveSheet): Action => ({
  type: ActionType.LOAD_SHEET,
  payload
})

const updateFormStep = (payload: number): Action => ({
  type: ActionType.UPDATE_STEP,
  payload
})

const setReportResult = (payload: ReportResult): Action => ({
  type: ActionType.SET_REPORT_RESULT,
  payload
})

const resetState = (): Action => ({type: ActionType.RESET})

export const usePersonatorContext = () => React.useContext(PersonatorContext);
export const registerPersonatorContext = () => React.useReducer(reducer, initialState);
export const DispatchActions = {
  loadSheet,
  updateFormStep,
  setReportResult,
  resetState
}
export default PersonatorContext;