import { APP_CONFIG, PACKAGE_RECORDS } from "../utilities/Constants";
import { OfficeExcelConfig, Request, formatCredit, getProcessingModeProdsAndPkgs, productIdToProcessingMode } from "../utilities/helpers";
import { CustomerInfoResp, OfficePersitProperty, ProcessingCount, eProcessingModes, eTokenServerResult } from "../utilities/models";
const { URL } = APP_CONFIG;

export class AuthService {
  private static _instance: AuthService

  static get instance() {
    return this._instance || (this._instance = new this())
  }

  /**
   * getCustomerInfo - get customer info from license key
   * @param license : string -> required
   * @returns CustomerInfoResp info
   */
  async getCustomerInfo(license: string, procModes: eProcessingModes = eProcessingModes.ALL & ~eProcessingModes.SmartMoverCheck): Promise<CustomerInfoResp | null> {
    if (license) {
      try {
        const { P, K } = getProcessingModeProdsAndPkgs(procModes)
        const params = {
          L: license,
          K,
          P
        }
        const resp = await Request({ baseURL: URL.creditURL }).get(`/QueryCustomerInfo?${new URLSearchParams(params)}`);
        const queryResponse: CustomerInfoResp = resp.data;


        if (queryResponse?.CustomerId) {
          OfficeExcelConfig.instance.setConfig(OfficePersitProperty.License, license);
          OfficeExcelConfig.instance.setConfig(OfficePersitProperty.Customer, JSON.stringify(queryResponse));
        }
        this.updateAccountInformation(queryResponse);
        return queryResponse;
      } catch (e) {
        return null;
      }
    }
    return null;
  }

  updateAccountInformation(queryResponse: CustomerInfoResp) {
    let queryResult = eTokenServerResult.Connectivity;
    if (queryResponse != null) queryResult = this.adjustServerResult(this.convertToResultEnum(queryResponse.Result), queryResponse);

    let webModes: eProcessingModes;
    let webModes_UsAllPlus: eProcessingModes = (eProcessingModes.USALL | eProcessingModes.BUSINESSCODERALL | eProcessingModes.MATCHUPALL | eProcessingModes.ReverseGeo);

    let subcriptionInfo: Record<string, string> = {
      'Customer ID:': queryResponse?.CustomerId
    };

    switch (queryResult) {
      case eTokenServerResult.Unknown:
        subcriptionInfo['Customer ID:'] = "(retrieving...)";
        break;
      case eTokenServerResult.MissingCustomerId:
        subcriptionInfo['Customer ID:'] = "(not specified)";
        subcriptionInfo['Credit Balance:'] = "(N/A)";
        break;
      case eTokenServerResult.InvalidCustomerId:
        subcriptionInfo['Customer ID:'] = "(invalid license)";
        subcriptionInfo['Credit Balance:'] = "(N/A)";
        break;
      case eTokenServerResult.Connectivity:
      case eTokenServerResult.InternalError:
        subcriptionInfo['Customer ID:'] = "Communications Error";
        subcriptionInfo['Credit Balance:'] = "(N/A)";
        break;
      case eTokenServerResult.CustomerNotFound:
        subcriptionInfo['NotSignedUp'] = "You are not signed up";
        break;
      case eTokenServerResult.UsingFullService:
        webModes = (queryResponse != null ? this.usesWebServices(queryResponse) : eProcessingModes.None);
        subcriptionInfo['Customer ID:'] = queryResponse.CustomerId;
        // subcriptionInfo['Web Service Subscription:'] = this.webSubscriptionLevel(webModes, true)
        // subcriptionInfo['Credit Usage:'] = "(N/A)";
        subcriptionInfo['US Subscription:'] = this.allProcessDescriptions((webModes & webModes_UsAllPlus), true); // USSubscription
        subcriptionInfo['Global Subscription:'] = this.allProcessDescriptions((webModes & eProcessingModes.INTALL), true); // IntSubscription
        subcriptionInfo['SmartMover Subscription:'] = this.allProcessDescriptions((webModes & eProcessingModes.SMARTMOVERALL), true); // SmartMoverSubscription
        subcriptionInfo['ExpressEntry Subs:'] = this.allProcessDescriptions((webModes & eProcessingModes.ExpressEntry), true); // ExpressEntrySubscription
        break;
      case eTokenServerResult.NoError:
      case eTokenServerResult.UsingCredits:
        webModes = (queryResponse != null ? this.usesWebServices(queryResponse) : eProcessingModes.None);
        subcriptionInfo['Customer ID:'] = queryResponse.CustomerId;
        subcriptionInfo['Credit Balance:'] = formatCredit(queryResponse.TotalCredits);
        subcriptionInfo['US Subscription:'] = this.allProcessDescriptions((webModes & webModes_UsAllPlus), true); // USSubscription
        subcriptionInfo['Global Subscription:'] = this.allProcessDescriptions((webModes & eProcessingModes.INTALL), true); // IntSubscription
        subcriptionInfo['SmartMover Subscription:'] = this.allProcessDescriptions((webModes & eProcessingModes.SMARTMOVERALL), true); // SmartMoverSubscription
        subcriptionInfo['ExpressEntry Subs:'] = this.allProcessDescriptions((webModes & eProcessingModes.ExpressEntry), true); // ExpressEntrySubscription
        break;
    }

    queryResponse.SubcriptionInfo = subcriptionInfo;
    queryResponse.LicenseType = queryResult;
    OfficeExcelConfig.instance.setConfig(OfficePersitProperty.Customer, JSON.stringify(queryResponse));
  }

  convertToResultEnum(result?: string): eTokenServerResult {
    if (result == null)
      return eTokenServerResult.Connectivity;
    else if (result.includes("SE01"))
      return eTokenServerResult.InternalError;
    else if (result.includes("TE01"))
      return eTokenServerResult.MissingCustomerId;
    else if (result.includes("TE02"))
      return eTokenServerResult.InvalidCustomerId;
    else if (result.includes("TE03"))
      return eTokenServerResult.CustomerNotFound;
    else if (result.includes("TE04"))
      return eTokenServerResult.UnauthorizedPackage;
    else if (result.includes("TE05"))
      return eTokenServerResult.NoRemainingRecords;
    else if (result.includes("TE06"))
      return eTokenServerResult.InvalidProductId;
    else if (result.includes("TE07"))
      return eTokenServerResult.NoPAF;
    else if (result.includes("TE08"))
      return eTokenServerResult.ExpiredPAF;
    else if (result.includes("TE09"))
      return eTokenServerResult.UnconfirmedPAF;
    else if (result.includes("TE10"))
      return eTokenServerResult.MultiplePAFs;
    else if (result.includes("TS01"))
      return eTokenServerResult.UsingFullService;
    else if (result.includes("TS02"))
      return eTokenServerResult.UsingCredits;
    return eTokenServerResult.NoError;
  }

  adjustServerResult(rslt: eTokenServerResult, resp: CustomerInfoResp) {
    if (rslt == eTokenServerResult.CustomerNotFound && resp != null) {
      const webModes: eProcessingModes = this.usesWebServices(resp);
      return (webModes != eProcessingModes.None ? eTokenServerResult.UsingFullService : eTokenServerResult.CustomerNotFound);
    }
    return rslt;
  }

  usesWebServices(customerInfo: CustomerInfoResp) {
    let retVal: eProcessingModes = eProcessingModes.None;
    for (let i = 0; customerInfo != null && customerInfo.PackageRecord != null && i < customerInfo.PackageRecord.length && i < customerInfo.ProductRecord.length; i++)
      if (this.convertToResultEnum(customerInfo.PackageRecord[i].Status) == eTokenServerResult.UsingFullService) {
        retVal |= productIdToProcessingMode(customerInfo.ProductRecord[i].Product);
      }
    return retVal;
  }

  webSubscriptionLevel(procMode: eProcessingModes, omitNationality: boolean) {
    let retVal = "";
    if ((procMode & eProcessingModes.USAPPEND) != 0)
      retVal = (!omitNationality ? "US " : "") + "Append";
    else if ((procMode & eProcessingModes.USMove) != 0)
      retVal = (!omitNationality ? "US " : "") + "Move";
    else if ((procMode & eProcessingModes.USVerify) != 0)
      retVal = (!omitNationality ? "US " : "") + "Verify";
    else if ((procMode & eProcessingModes.USCheck) != 0)
      retVal = (!omitNationality ? "US " : "") + "Check"

    if ((procMode & eProcessingModes.USGeoPoint) != 0)
      retVal = (retVal + " " + (!omitNationality ? "US " : "") + "GeoPoint Location").trim();
    else if ((procMode & eProcessingModes.USGeoCode) != 0)
      retVal = (retVal + " " + (!omitNationality ? "US " : "") + "GeoCode Location").trim();

    if ((procMode & eProcessingModes.USDEMOGRAPHICS) != 0)
      retVal = (retVal + " " + (!omitNationality ? "US " : "") + "Demographics").trim();
    if ((procMode & eProcessingModes.USProperty) != 0)
      retVal = (retVal + " " + (!omitNationality ? "US " : "") + "Property").trim();

    if ((procMode & eProcessingModes.IntCheck) != 0)
      retVal = (retVal + " " + (!omitNationality ? "Int " : "") + "Check").trim();
    if ((procMode & eProcessingModes.IntGeoPoint) != 0)
      retVal = (retVal + " " + (!omitNationality ? "Int " : "") + "GeoPoint Location").trim();
    else if ((procMode & eProcessingModes.IntGeoCode) != 0)
      retVal = (retVal + " " + (!omitNationality ? "Int " : "") + "GeoCode Location").trim();

    if ((procMode & eProcessingModes.SmartMoverNCOA) != 0)
      retVal = (retVal + " SmartMover (US)").trim();
    if ((procMode & eProcessingModes.SmartMoverCCOA) != 0)
      retVal = (retVal + " SmartMover (Canada)").trim();

    if ((procMode & eProcessingModes.BusinessCoder_Listware) != 0)
      retVal = (retVal + " Business Coder Company/Address Match").trim();
    if ((procMode & eProcessingModes.BusinessCoder_AddressValidate) != 0)
      retVal = (retVal + " Business Coder Address Validate").trim();
    if ((procMode & eProcessingModes.BusinessCoder_GeoPoint) != 0)
      retVal = (retVal + " Business Coder GeoPoint").trim();
    if ((procMode & eProcessingModes.BusinessCoder_FirmgraphicsAppend) != 0)
      retVal = (retVal + " Business Coder Firmographics Append").trim();

    if ((procMode & eProcessingModes.MatchUpCheck) != 0)
      retVal = (retVal + " MatchUp Check").trim();
    if ((procMode & eProcessingModes.MatchUpDedupe) != 0)
      retVal = (retVal + " MatchUp Duplicates").trim();

    if ((procMode & eProcessingModes.ReverseGeo) != 0)
      retVal = (retVal + " Reverse GeoCode").trim();

    return retVal;
  }

  allProcessDescriptions(procMode: eProcessingModes, omitNationality?: boolean, delimiter?: string) {
    const retVal: string[] = [
      (procMode & eProcessingModes.USCheck) != 0 ? `${!omitNationality ? 'US ' : ''}Check` : undefined,
      (procMode & eProcessingModes.USVerify) != 0 ? `${!omitNationality ? 'US ' : ''}Verify` : undefined,
      (procMode & eProcessingModes.USMove) != 0 ? `${!omitNationality ? 'US ' : ''}Move Update` : undefined,
      (procMode & eProcessingModes.USAppendName) != 0 ? `${!omitNationality ? 'US ' : ''}Append Names/Companies` : undefined,
      (procMode & eProcessingModes.USAppendAddress) != 0 ? `${!omitNationality ? 'US ' : ''}Append Addresses` : undefined,
      (procMode & eProcessingModes.USAppendPhone) != 0 ? `${!omitNationality ? 'US ' : ''}Append Phones` : undefined,
      (procMode & eProcessingModes.USAppendEmail) != 0 ? `${!omitNationality ? 'US ' : ''}Append E-mails` : undefined,
      (procMode & eProcessingModes.USGeoPoint) != 0 ? `${!omitNationality ? 'US ' : ''}GeoPoint Location` : undefined,
      (procMode & eProcessingModes.USGeoCode) != 0 ? `${!omitNationality ? 'US ' : ''}GeoCode Location` : undefined,
      (procMode & eProcessingModes.USDEMOGRAPHICS) != 0 ? `${!omitNationality ? 'US ' : ''}Demographics` : undefined,
      (procMode & eProcessingModes.USProperty) != 0 ? `${!omitNationality ? 'US ' : ''}Property` : undefined,
      (procMode & eProcessingModes.IntCheck) != 0 ? `${!omitNationality ? 'Intl ' : ''}Check` : undefined,
      (procMode & eProcessingModes.IntGeoPoint) != 0 ? `${!omitNationality ? 'Intl ' : ''}GeoPoint Location` : undefined,
      (procMode & eProcessingModes.IntGeoCode) != 0 ? `${!omitNationality ? 'Intl ' : ''}GeoCode Location` : undefined,
      (procMode & eProcessingModes.SmartMoverCheck) != 0 ? 'SmartMover Address Check' : undefined,
      (procMode & eProcessingModes.SmartMoverNCOA) != 0 ? 'SmartMover (US)' : undefined,
      (procMode & eProcessingModes.SmartMoverCCOA) != 0 ? 'SmartMover (Canada)' : undefined,
      (procMode & eProcessingModes.BusinessCoder_Listware) != 0 ? 'Business Coder Company/Address Match' : undefined,
      (procMode & eProcessingModes.BusinessCoder_AddressValidate) != 0 ? 'Business Coder Address Validate' : undefined,
      (procMode & eProcessingModes.BusinessCoder_GeoPoint) != 0 ? 'Business Coder GeoPoint' : undefined,
      (procMode & eProcessingModes.BusinessCoder_FirmgraphicsAppend) != 0 ? 'Business Coder Firmographics Append' : undefined,
      (procMode & eProcessingModes.MatchUpCheck) != 0 ? 'MatchUp Check' : undefined,
      (procMode & eProcessingModes.MatchUpDedupe) != 0 ? 'MatchUp Duplicates' : undefined,
      (procMode & eProcessingModes.ReverseGeo) != 0 ? `${!omitNationality ? 'Intl ' : ''}Reverse GeoCode` : undefined,
      (procMode & eProcessingModes.ExpressEntry) != 0 ? 'ExpressEntry' : undefined,
    ].filter(f => f);

    return retVal.join(delimiter ?? ', ');
  }

  createProcessingCountArray() {
    const queryResponse: CustomerInfoResp = OfficeExcelConfig.instance.getConfig(OfficePersitProperty.Customer, true)
    const retVal: ProcessingCount[] = Array.from({ length: PACKAGE_RECORDS.length });
    for (let i = 0; i < PACKAGE_RECORDS.length; i++) {
      retVal[i] = new ProcessingCount(PACKAGE_RECORDS[i].processingMode);

      for (let j = 0; queryResponse != null && queryResponse?.ProductRecord != null && j < queryResponse?.ProductRecord?.length; j++) {
        if (productIdToProcessingMode(queryResponse.ProductRecord[j].Product) == retVal[i].processingMode) {
          retVal[i].cost = queryResponse.ProductRecord[j].Cost;
          break;
        }
      }
    }
    return retVal;
  }
}