import { Toaster } from "./Toaster";
import { RouterUtil } from "./Router";
import { OfficeExcelConfig } from ".";
import { OfficePersitProperty } from "../models";

export const RibbonAction: Record<string, () => Promise<any>> = {
  PersonatorVerify: () => {
    RouterUtil.instance.push('/');
    if (!OfficeExcelConfig.instance.getConfig(OfficePersitProperty.License)) {
      Toaster.warn("Looks like you haven't set the license key. Please update!");
    }
    return Office.addin.showAsTaskpane()
  },
  OpenSetting: () => {
    RouterUtil.instance.push('/setting')
    return Office.addin.showAsTaskpane()
  },
}