import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Typography } from "../../components";
import { Checkbox } from "@fluentui/react-components";
import { OPERATIONS } from "../../utilities/Constants";
import EstimateCreditUsage from "./EstimateCreditUsage";
import useCustomerLicenseType from "../../hooks/useCustomerLicenseType";

export default function CleanOperations() {
  const { control, setValue } = useFormContext();
  const { usingCredits } = useCustomerLicenseType()
  const operations: Operation[] = useWatch({
    control,
    name: "operations",
  });
  const handleChange = (value: Operation) => () => {
    const values = operations.includes(value) ? operations.filter((d) => d !== value) : [...operations, value];
    setValue("operations", values);
  };

  const getItemDisabled = React.useCallback((item: OperationItem) => {
    return item.disabled || (usingCredits && item.value === "Verify" && operations.some(opt => ["Move", "Append"].includes(opt)))
  }, [operations])

  React.useEffect(() => {
    if (usingCredits && operations.some(opt => ["Move", "Append"].includes(opt)) && !operations.includes("Verify")) {
      setValue('operations', [...operations, "Verify"])
    }
  }, [operations])

  return (
    <>
      <Typography variant="h1" block className="text-blue-400">
        Clean Operations
      </Typography>
      <Typography block className="mt-4 text-gray-500 italic">
        Select the operation(s) you want to peform:
      </Typography>
      
      {
        usingCredits && <Typography block italic small className="mt-2 text-yellow-500 italic">
          The `Verify` operation is required for the `Move` and `Append`.
        </Typography>
      }

      <Typography block className="mt-2">
        Select Operation(s):
      </Typography>
      <div className="flex flex-col mt-2">
        {OPERATIONS.map((item) => (
          <Checkbox
            // Verify option will required by Move or Append
            disabled={getItemDisabled(item)}
            key={item?.value}
            label={item.label}
            checked={operations.includes(item?.value)}
            onChange={handleChange(item.value)}
          />
        ))}
      </div>

      <EstimateCreditUsage showEstimateDescription showCreditUsageLink />
    </>
  );
}
