import React from 'react'
import { OfficeExcelConfig } from '../utilities/helpers'
import { CustomerInfoResp, OfficePersitProperty, eTokenServerResult } from '../utilities/models'

export default function useCustomerLicenseType() {
    const customerInfo: CustomerInfoResp = OfficeExcelConfig.instance.getConfig(OfficePersitProperty.Customer, true);

    const customerLicenseType = React.useMemo(() => {
        const licenseType: { usingCredits?: boolean; usingFullService?: boolean } = {};
        switch (customerInfo.LicenseType) {
            case eTokenServerResult.NoError:
            case eTokenServerResult.UsingCredits:
                licenseType.usingCredits = true;
                licenseType.usingFullService = false;
                break;
            case eTokenServerResult.UsingFullService:
                licenseType.usingCredits = false;
                licenseType.usingFullService = true;
                break;
        }
        return licenseType;
    }, [customerInfo])

    return customerLicenseType
}
