import React from "react";
import { Input, Button, Field } from "@fluentui/react-components";
import { ArrowCounterclockwise16Filled } from "@fluentui/react-icons";
import { AppVersion, Loader, ProgressBarModal, Typography } from "../../components";
import { useLocation, useNavigate } from "react-router-dom";
import AccountInfo from "./AccountInfo";
import { OfficeExcelConfig, Toaster } from "../../utilities/helpers";
import { AuthService } from "../../services/Auth";
import { DialogRef } from "../../components/Dialog";
import { CustomerInfoResp, OfficePersitProperty } from "../../utilities/models";

export default function Settings() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dialogRef = React.useRef<DialogRef>(null);
  const [loader, setLoader] = React.useState<boolean>(true);
  const [license, setLicense] = React.useState<{
    value: string | undefined;
    validationState?: "error" | "warning" | "success" | "none";
    validationMessage?: string | undefined;
  }>({
    value: "",
    validationState: "none",
  });

  React.useEffect(() => {
    const license = OfficeExcelConfig.instance.getConfig(OfficePersitProperty.License);
    if (license) {
      setLoader(true);
      AuthService.instance.getCustomerInfo(license).finally(() => setLoader(false));
    } else {
      // eslint-disable-next-line no-undef
      setTimeout(() => {
        setLoader(false);
      }, 500);
    }
  }, [OfficeExcelConfig.instance.getConfig(OfficePersitProperty.License)]);

  const applyLicense = async () => {
    if (!license?.value) {
      setLicense({
        ...license,
        validationState: "error",
        validationMessage: "License key is required.",
      });
      return;
    }
    dialogRef.current.trigger(true);
    const customer: CustomerInfoResp = await AuthService.instance.getCustomerInfo(license?.value);
    if (customer?.CustomerId) {
      Toaster.success("Your license has been updated!");
    } else {
      Toaster.warn("The license seems not correct! please try again.");
    }
    dialogRef.current.trigger();
    setLicense({ value: "" });
    if (state?.redirectTo) {
      const { redirectTo } = state;
      navigate(`${redirectTo.pathname}${redirectTo.search}`);
    }
  };

  return (
    <>
      <Loader full opacity={0.7} visible={loader} />
      <Typography variant="h1" block className="text-blue-400">
        License Key
      </Typography>
      <Typography block italic className="mt-2">
        Paste in the License Key given to you via email from Melissa Data to update or inject to add-on.
      </Typography>
      <div className="flex flex-row mt-5 w-full gap-2 justify-evenly items-start">
        <Field
          className="flex-1"
          validationState={license?.validationState}
          validationMessage={license?.validationMessage}
        >
          <Input
            autoComplete="none"
            aria-autocomplete="none"
            placeholder="Enter license key"
            type="password"
            value={license?.value}
            onChange={(e) => setLicense({ value: e.target.value })}
          />
        </Field>
        <Button
          onClick={applyLicense}
          appearance="transparent"
          icon={<ArrowCounterclockwise16Filled />}
          size="medium"
        />
      </div>
      <AccountInfo />
      <AppVersion />
      <ProgressBarModal ref={dialogRef} message="Syncing your license key..." />
    </>
  );
}
