import { OfficePersitProperty } from "../models";

export class OfficeExcelConfig {
    private static _instance: OfficeExcelConfig;

    static get instance() {
        return this._instance || (this._instance = new this())
    }

    setConfig(key: OfficePersitProperty, value: string | boolean) {
        Office.context.document?.settings.set(OfficePersitProperty[key], value);
        Office.context.document?.settings.saveAsync();
    }

    getConfig(key: OfficePersitProperty, parse?: boolean) {
        const value = Office.context.document?.settings.get(OfficePersitProperty[key]);
        return parse ? JSON.parse(value || '{}') : value;
    }

    remove(key: OfficePersitProperty) {
        Office.context.document?.settings.remove(OfficePersitProperty[key]);
        Office.context.document?.settings.saveAsync();
    }
}

export const getAddress = (address?: string) => {
    return address?.slice(address?.lastIndexOf("!") + 1)
}

export const getDefaultFontFormat = (sheetName?: string) => {
    return Excel.run(async (context) => {
        const { worksheets } = context.workbook;
        const sheet: Excel.Worksheet = sheetName ? worksheets.getItem(sheetName) : worksheets.getActiveWorksheet();

        const range = sheet.getRangeByIndexes(0, 0, 1, 1);
        range.format.font.load(["name", "size"]);

        await context.sync();
        return {
            name: range.format.font?.name || "Arial",
            size: range.format.font?.size || 10
        }
    })
}