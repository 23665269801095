import * as React from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import { useId, Toaster as ToastComp, useToastController, ToastTitle, Toast } from "@fluentui/react-components";
import Settings from "./taskpanes/settings";
import Result from "./taskpanes/result/Result";
import { ProtectedRoute } from "./components";
import Personator from "./taskpanes/personator";
import { Toaster, ToastOptions } from "./utilities/helpers";
import PersonatorContext, { DispatchActions, registerPersonatorContext } from "./state/Personator";

const App = () => {
  const [state, dispatch] = registerPersonatorContext();
  const personatorState = React.useMemo(() => ({ state, dispatch }), [state, dispatch]);
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  React.useEffect(() => {
    Toaster.instance.on("toast", ({ message, ...opts }: ToastOptions) => {
      message &&
        dispatchToast(
          <Toast>
            <ToastTitle>{message}</ToastTitle>
          </Toast>,
          opts
        );
    });

    return () => {
      Toaster.instance.removeAllListeners();
    };
  }, []);

  Office.onReady(() => {
    Office?.addin?.onVisibilityModeChanged((r) => {
      if (r.visibilityMode === "Hidden")
        dispatch(DispatchActions.resetState());
    });
  });

  return (
    <div className="min-h-screen p-3">
      <PersonatorContext.Provider value={personatorState}>
        <HashRouter>
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Personator />
                </ProtectedRoute>
              }
            />
            <Route path="/setting" element={<Settings />} />
            <Route path="/results" element={<Result />} />
          </Routes>
        </HashRouter>
      </PersonatorContext.Provider>
      <ToastComp toasterId={toasterId} limit={3} />
    </div>
  );
};

export default App;
