import React from "react";
import { Button, ProgressBar } from "@fluentui/react-components";
import { FormProvider } from "react-hook-form";
import { ProgressBarModal, Typography } from "../../components";
import usePersonatorHandler from "./Personator.handler";
import { DialogRef } from "../../components/Dialog";
import { PersonatorFormStep } from "../../utilities/models";

export default function Personator() {
  const dialogRef = React.useRef<DialogRef>(null);
  const { step, tabs, form, containerRef, handleNext, formError, handleBack, executedTime } = usePersonatorHandler({
    dialogRef,
  });

  const Form = React.useMemo(() => {
    return (
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleNext)}>
          <ProgressBar value={step / Object.keys(tabs).length} shape="rounded" thickness="large" className="mb-5" />
          {tabs[step]}
          <Typography smaller italic block className="my-3 text-red-500">
            {formError}
          </Typography>
          <div className="fixed bottom-0 right-4 bg-white w-full h-20">
            <div className="flex justify-end gap-3 items-center h-full">
              {step > PersonatorFormStep.Operation && (
                <Button onClick={handleBack} appearance="primary">
                  Previous
                </Button>
              )}
              <Button type="submit" appearance="primary">
                {step == PersonatorFormStep.ReviewSetting ? "Process" : "Next"}
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    );
  }, [form, handleNext, step, tabs, handleBack]);

  return (
    <div className="pb-24" ref={containerRef}>
      {Form}
      <ProgressBarModal ref={dialogRef}>
        <div className="flex flex-col py-3 items-center">
          <Typography block>Your request is being processing...</Typography>
          <Typography block>Please wait.</Typography>
          <Typography>Executed time: {executedTime}</Typography>
        </div>
      </ProgressBarModal>
    </div>
  );
}
