import { eCustomerInfoPackages, eProcessingModes } from '../models';
import { CreditReportingInfo } from '../models/CreditReportingInfo';

export * from './AppConfig'
export * from './PackageInfoRecord'
export * from './StatusCode'
export * from './OutputField'

export const OPERATIONS: OperationItem[] = [
    { value: "Check", label: "Check Address", disabled: true },
    { value: "Verify", label: "Verify Contact" },
    { value: "Move", label: "Move Update" },
    { value: "Append", label: "Append" }
];

export const OUTPUT_KEYS_ACCORDIONS: Record<string, string> = {
    "name1": "Name",
    "cbAddress": "Address",
    "cbAddressInformation": "Address Information",
    "cbCensusInformation": "Census Information",
    "cbDemographics": "Demographics",
    "cbParsedAddress": "Parsed Address",
    "cbOutSalutation": "Salutation",
    "cbOutGeoLocation": "GeoLocation",
    "cbOutPhone": "Phone",
    "cbOutEmail": "Email"
}

export const INPUT_COLS: InputField[] = [
    { label: "Full Name:", id: "FullName", key: "FullName,Full", prefix: "name" },
    { label: "First Name:", id: "FirstName", key: "FirstName,First", prefix: "name" },
    { label: "Last Name:", id: "LastName", key: "LastName,Last", prefix: "name" },
    { label: "Company:", id: "CompanyName", key: "Company,Company Name", prefix: "address" },
    { label: "Address", id: "AddressLine1", key: "Address,FullAddress,Full Address,Address1", prefix: "address" },
    { label: "Address 2", id: "AddressLine2", key: "Address2", prefix: "address" },
    { label: "City", id: "City", key: "City", prefix: "address" },
    { label: "State", id: "State", key: "State", prefix: "address" },
    { label: "Postal Code:", id: "PostalCode", key: "ZipCode,Zip,PostalCode,Postal Code", prefix: "address" },
    { label: "Phone Number:", id: "PhoneNumber", key: "Phone,PhoneNumber,Phone Number", prefix: "phone" },
    { label: "Email:", id: "EmailAddress", key: "Email,EmailAddress", prefix: "email" },
];

export const COLORS = {
    DEFAULT: "#FAF1E4",
    GOOD: "#A0C49D",
    QUITE_GOOD: "#C4D7B2",
    BAD: "#F6635C"
};

export const BATCH_SIZE = 100;

export const CreditReportingInfos: CreditReportingInfo[] = [
    new CreditReportingInfo(eCustomerInfoPackages.USCheck, eProcessingModes.USCheck, null, ["AE07"]),
    new CreditReportingInfo(eCustomerInfoPackages.USVerify, eProcessingModes.USVerify, null, ["AE07"]),
    new CreditReportingInfo(eCustomerInfoPackages.USMove, eProcessingModes.USMove, ["AS12"], null),
    new CreditReportingInfo(eCustomerInfoPackages.USGeoCode, eProcessingModes.USGeoCode, ["GS01"], null),
    new CreditReportingInfo(eCustomerInfoPackages.USGeoPoint, eProcessingModes.USGeoPoint, ["GS05", "GS06"], null),
    new CreditReportingInfo(eCustomerInfoPackages.USAppendName, eProcessingModes.USAppendName, ["DA10", "DA20"], null),
    new CreditReportingInfo(eCustomerInfoPackages.USAppendAddress, eProcessingModes.USAppendAddress, ["DA00"], null),
    new CreditReportingInfo(eCustomerInfoPackages.USAppendPhone, eProcessingModes.USAppendPhone, ["DA30"], null),
    new CreditReportingInfo(eCustomerInfoPackages.USAppendEmail, eProcessingModes.USAppendEmail, ["DA40"], null)
];

export const RECORDS_PER_BATCH = 100;