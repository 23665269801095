import React from "react";
import { Dialog, Typography } from "../../components";
import { Button, Link } from "@fluentui/react-components";
import { DialogRef } from "../../components/Dialog";
import { Toaster, OfficeExcelConfig, formatCredit } from "../../utilities/helpers";
import { APP_CONFIG } from "../../utilities/Constants";
import { CustomerInfoResp, OfficePersitProperty } from "../../utilities/models";

export default function AccountInfo() {
  const dialogRef = React.useRef<DialogRef>(null);
  const [_, forceUpdate] = React.useReducer((x) => !x, false);
  const license: string = OfficeExcelConfig.instance.getConfig(OfficePersitProperty.License);
  const user: CustomerInfoResp = OfficeExcelConfig.instance.getConfig(OfficePersitProperty.Customer, true);
  const subscriptionInfo = user?.SubcriptionInfo || {
    'Customer ID: ': user.CustomerId,
    'Credit Balance: ': formatCredit(user.TotalCredits)
  };

  const confirmClearLicense = () => {
    OfficeExcelConfig.instance.remove(OfficePersitProperty.License);
    OfficeExcelConfig.instance.remove(OfficePersitProperty.HideTutorial);
    OfficeExcelConfig.instance.remove(OfficePersitProperty.Customer);
    dialogRef.current.trigger();
    Toaster.success("Your license has been removed!");
    forceUpdate();
  };

  const onDialogCancel = React.useCallback(() => dialogRef.current.trigger(), [dialogRef.current]);
  const markedLicense = () => {
    return `${license
      ?.slice(0, license?.length - 4)
      .split("")
      .map((_) => "*")
      .join("")}${license?.slice(-4)}`;
  };

  const AccountContent = React.useMemo(() => {
    if (license)
      return (
        <>
          <Typography variant="h1" className="mt-8 mb-4 text-blue-400" block>
            Your Account
          </Typography>

          {Object.entries(subscriptionInfo).map(([key, value]) => {
            if (!value) return null;
            return <Typography key={key} block className="mb-2">
              <Typography as="span" className="font-semibold">
                {key}
              </Typography>{" "}
              <span className="text-gray-500">{value}</span>
            </Typography>
          })}

          <Typography block className="mt-4 text-neutral-500">
            <Typography as="span" className="font-medium">
              License:
            </Typography>{" "}
            {markedLicense()}
          </Typography>

          <div className="mt-8 flex items-center justify-center gap-2">
            <Button appearance="primary">Purchase Credits</Button>
            <Button onClick={() => dialogRef.current.trigger(true)} appearance="primary">
              Clear License
            </Button>
          </div>

          <Dialog
            ref={dialogRef}
            onSecondaryClick={onDialogCancel}
            onPrimaryClick={confirmClearLicense}
            title="Warning"
            content="Are you sure to clear license key?"
          />
        </>
      );

    return (
      <div className="mt-5 text-center">
        <Typography className="mb-3" block>
          No license key to use Listware?
        </Typography>
        <Link target="_blank" href={APP_CONFIG.URL.registerURL}>Sign up Here to get the license key!</Link>
      </div>
    );
  }, [license, user]);

  return AccountContent;
}
