import React from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  AccordionToggleEventHandler,
  Checkbox,
} from "@fluentui/react-components";
import { OUTPUT_COLUMNS, OUTPUT_KEYS_ACCORDIONS } from "../../utilities/Constants";
import { Typography } from "../../components";
import { useFormContext, useWatch } from "react-hook-form";

interface AccordionItem {
  label: string;
  value: string;
  field: string;
  header: string;
}

function OutputGroup(props: { name: string; options: AccordionItem[] }) {
  const { control, setValue } = useFormContext();
  const field: string[] = useWatch({
    control,
    name: props.name,
  });

  const handleChange = (value: string) => () => {
    const values = field.includes(value) ? field.filter((d) => d !== value) : [...field, value];
    setValue(props.name, values);
  };

  return (
    <>
      {props.options.map((item: AccordionItem) => (
        <div className="flex flex-col" key={item.field}>
          <Checkbox
            key={item?.field}
            label={item.label}
            checked={field.includes(item?.field)}
            onChange={handleChange(item.field)}
          />
        </div>
      ))}
    </>
  );
}

export default function OutputColumns() {
  const [openItems, setOpenItems] = React.useState([0]);
  const handleToggle: AccordionToggleEventHandler<number> = (_event, data) => {
    setOpenItems(data.openItems);
  };

  const classifiedColumns = React.useMemo(
    () =>
      Object.values(
        OUTPUT_COLUMNS.reduce((classified, column) => {
          const { prefix, header, ...rest } = column;

          if (!classified[prefix]) {
            classified[prefix] = { [prefix]: [] };
          }
          classified[prefix][prefix].push({ header, ...rest });
          return classified;
        }, {})
      ),
    []
  );

  return (
    <>
      <Typography variant="h1" block className="text-blue-400">
        Output Columns
      </Typography>
      <Typography block className="mt-2 text-gray-500 italic">
        Specify what data you would like to output. New columns will be inserted to the right of the input range for
        these new columns.
      </Typography>
      <Accordion openItems={openItems} onToggle={handleToggle} collapsible>
        {classifiedColumns.map((accordion, idx) => (
          <AccordionItem value={idx} key={idx}>
            <AccordionHeader expandIconPosition="end">
              <Typography variant="h3" block className="text-blue-400">
                {OUTPUT_KEYS_ACCORDIONS[Object.keys(accordion)[0]]}
              </Typography>
            </AccordionHeader>
            <AccordionPanel>
              {Object.values(accordion).map((options, idx) => (
                <OutputGroup key={idx} name={Object.keys(accordion)[0]} options={options} />
              ))}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </>
  );
}
