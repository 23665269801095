import * as React from "react";
import { Dropdown as FluentDropdown, Option, useId } from "@fluentui/react-components";
import { useFormContext, useWatch } from "react-hook-form";

export interface DropdownOption {
  value: string;
  key?: string | number;
  label: string;
  disabled?: boolean;
}
interface DropdownProps {
  name: string;
  label: string;
  placeholder?: string;
  disablePlaceholder?: boolean;
  options?: DropdownOption[];
}

export const Dropdown = ({
  label,
  name,
  placeholder = "--- Blank ---",
  disablePlaceholder = false,
  options = [],
}: DropdownProps) => {
  const dropdownId = useId();
  const { setValue, control } = useFormContext();
  const field = useWatch({
    control,
    name,
  });

  const selected = React.useMemo(() => options.find((d) => d.value === field)?.label, [field]);

  return (
    <div className="mt-4 flex gap-2 items-center">
      {label && (
        <label id={dropdownId} className="mr-2 min-w-[80px]">
          {label}
        </label>
      )}
      <FluentDropdown
        className="!min-w-max flex-1"
        appearance="underline"
        size="medium"
        aria-labelledby={dropdownId}
        value={selected}
        onOptionSelect={(_, data) => setValue(name, data?.optionValue, { shouldValidate: true })}
        placeholder={placeholder}
      >
        <Option disabled={disablePlaceholder} value="">
          {placeholder}
        </Option>
        {options.map((option) => (
          <Option value={option?.value} key={`${option?.key}-${option?.value}`} disabled={option?.disabled}>
            {option?.label}
          </Option>
        ))}
      </FluentDropdown>
    </div>
  );
};
