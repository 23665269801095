import React from "react";
import { Typography } from "../../components";
import { Link } from "@fluentui/react-components";
import { APP_CONFIG } from "../../utilities/Constants";
import { OfficeExcelConfig, formatCredit } from "../../utilities/helpers";
import { CustomerInfoResp, OfficePersitProperty, eProcessingModes } from "../../utilities/models";
import { useFormContext } from "react-hook-form";
import { usePersonatorContext } from "../../state/Personator";
import { PersonatorService } from "../../services/Personator";
import useCustomerLicenseType from "../../hooks/useCustomerLicenseType";
import { AuthService } from "../../services/Auth";

interface EstimateCreditUsageProps {
    showEstimateDescription?: boolean;
    showCreditUsageLink?: boolean;
    isReview?: boolean;
}

export default function EstimateCreditUsage(props: EstimateCreditUsageProps) {
    const { URL } = APP_CONFIG;
    const customerInfo: CustomerInfoResp = OfficeExcelConfig.instance.getConfig(OfficePersitProperty.Customer, true);
    const { usingFullService } = useCustomerLicenseType()
    const { watch } = useFormContext();
    const { state } = usePersonatorContext();
    const [operations, processingMode] = watch(["operations", "processingMode"]);

    const { creditUsage, highEstimate } = React.useMemo(() => {
        const rtnVal = PersonatorService.instance.estimateCreditUsage(processingMode, state?.sheet?.totalRows);
        return {
            creditUsage: rtnVal.lowEstimate,
            highEstimate: rtnVal.highEstimate,
        };
    }, [operations, processingMode, state.sheet]);

    const webServiceSubsription = React.useMemo(() => {
        if (usingFullService) {
            const webModes = (customerInfo != null ? AuthService.instance.usesWebServices(customerInfo) : eProcessingModes.None);
            return AuthService.instance.webSubscriptionLevel(webModes, true)
        }
        return ''
    }, [usingFullService, customerInfo])

    if (usingFullService && props.isReview) return null

    return (
        <div className="mt-4">
            {usingFullService ? <>
                <Typography block>
                    <span className="font-semibold">Web Service Subscription:</span> <span className="text-gray-500">{webServiceSubsription}</span>
                </Typography>
                <Typography block className="my-2">
                    <span className="font-semibold">Credit Usage:</span> <span className="text-gray-500">(N/A)</span>
                </Typography>
            </> : <>
                <Typography block className="whitespace-nowrap">
                    <span className="font-semibold">Credit Balance:</span> <span className="text-gray-500">{formatCredit(customerInfo.TotalCredits)}</span>
                </Typography>
                <Typography block className="my-2 whitespace-nowrap">
                    <span className="font-semibold">Credit Usage:</span> <span className="text-gray-500">{creditUsage}</span>
                </Typography>
                {props.showEstimateDescription && operations.some((opt) => ["Move", "Append"].includes(opt)) && (
                    <Typography block italic small className="mt-3 italic text-gray-500">
                        * Estimated, actual usage may differ depending on the quality of your data. Refer to the updated estimate
                        on the Review page before processing.
                    </Typography>
                )}
                {props.isReview && (
                    <>
                        <Typography block className="mt-2">Maximum usage: {highEstimate}</Typography>
                        <Typography block italic small className="text-gray-500 mt-3 italic">
                            * The 'Usage' figure provided above is an estimate based on typical data. Your usage may be higher.
                        </Typography>
                    </>
                )}
                {props.showCreditUsageLink && <Typography italic small className="mt-3 text-gray-500 italic">
                    <Link href={URL.creditUsageURL}>Click here,</Link> for more details on how credits are used.
                </Typography>}
            </>}

        </div>
    );
}
