import { eProcessingModes } from ".";

export class PackageInfoRecord {
    productId: string;
    queryPackageId: string;
    processingPackageId: string;
    processingMode: eProcessingModes;

    constructor(
        productId: string,
        queryPackageId: string,
        processingPackageId: string,
        processingMode: eProcessingModes,
    ) {
        this.productId = productId;
        this.queryPackageId = queryPackageId;
        this.processingPackageId = processingPackageId;
        this.processingMode = processingMode;
    }
}