import * as React from "react";
import {
  Dialog as FluentDialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
} from "@fluentui/react-components";

interface DialogProps {
  title?: string;
  content?: string | React.ReactNode;
  primaryBtnText?: string;
  secondaryBtnText?: string;
  onOpenChange?: () => void;
  onPrimaryClick?: () => void;
  onSecondaryClick?: () => void;
}

export type DialogRef = {
  trigger: (open?: boolean) => void;
};

export default React.forwardRef<DialogRef, DialogProps>(function Dialog(props, ref) {
  const [open, setOpen] = React.useState<boolean>(false);
  const trigger = (open?: boolean) => setOpen(!!open);
  const {
    title = "Dialog Title",
    content = "Dialog Content",
    primaryBtnText = "Ok",
    secondaryBtnText = "Cancel",
    onSecondaryClick = () => open && setOpen(false),
    onPrimaryClick = () => open && setOpen(false),
  } = props;

  React.useImperativeHandle(
    ref,
    () => ({
      trigger,
    }),
    []
  );

  if (!open) return null;

  return (
    <FluentDialog open={open} onOpenChange={props.onOpenChange}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>{content}</DialogContent>
          <DialogActions className="!flex-row !justify-end">
            <DialogTrigger disableButtonEnhancement>
              <Button onClick={onPrimaryClick} appearance="primary">
                {primaryBtnText}
              </Button>
            </DialogTrigger>
            <Button onClick={onSecondaryClick} appearance="secondary">
              {secondaryBtnText}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </FluentDialog>
  );
});
